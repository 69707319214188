<template>
  <div class="ia4">
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 mb-5">
            <h3>监测领域</h3>
            <slot/>
          </div>
          <div class="col-lg-8 col-md-6 col-sm-12 col-12">
            <div class="row mb-5">
              <img :src="require(`@/assets/images/fs/ia-04-1.jpg`)" alt="交通领域智能安全监测" title="交通领域智能安全监测">
            </div>
            <div class="row">
              <h3 class="text-center">行业前景</h3>
              <p>
                <span class="w-600">公路护栏前景概述：</span>
                <span class="small">根据调研，截至2019年，我国的公路总里程约为484.65万KM，高速公路约为14.26万KM，位居世界之首。然而，尽管我国的公路事故指标（事故发生数死亡数、受伤数、直接财产损失）得到有效控制，但是在普通干线公路上的事故中约有45％是车辆越出道路引起的，而在高速公路中则为30％，同时产生的特大及重大恶性交通事故比例为60％。我国交通事故造成的死亡人数达到全世界的1/7，由车辆碰撞公路路侧死亡人数约为1/3，且人数每年增加，均为护栏碰撞事故。</span>
              </p>
              <p>
                <span class="w-600">山体落石前景概述:</span>
                <span class="small">我国是山地大国，崩塌、滚石等自然灾害较多，给公路运营带来的损失居高不下。当滚石侵入公路限界后，由于司机可视距离有限，特别是晚上或者是大雾天气，一旦山体有石头滑落，极容易发生撞击事故，甚至造成更为巨大的灾难，且石头滑落的速度极快，会让正在行驶的车辆防不胜防。类似的事件在我国已发生多起，直接导致重大的经济损失，甚至威胁司机的生命安全。</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Separator/>
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-5">
            <h3>高速公路护栏监测</h3>
            <h5>监测系统</h5>
            <p>
              振动监测主机放置在机房内，监测光缆铺设在护栏的外侧，系统连接光缆，利用分布式光纤振动系统对公路护栏的振动情况进行全天候24小时实时在线监测，当护栏发生汽车撞击、破坏等振动信号时，系统可立即对振动撞击信号进行捕捉，同时精准定位事故位置，并第一时间把报警信息上传至集中监控平台。
            </p>
          </div>
          <div class="col-lg-5 col-md-5 offset-lg-2 offset-md-2">
            <h5>监测平台显示</h5>
            <p>根据现场所提供的GIS地图，导入到软件中，直观的展示现场光纤走向。</p>
            <p>报警时，会自动弹出一个报警窗口。</p>
            <p>精确定位报警位置、报警时间</p>
            <p>振动信号显示、故障诊断报警</p>
            <p>排除干扰信号、防区报警事件处理</p>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <img :src="require('@/assets/images/fs/ia-04-2.png')" alt="">
          </div>
        </div>
      </div>
    </div>

    <Separator/>
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-5">
            <h3>公路护栏重点区域监测</h3>
            <h5>监测系统</h5>
            <p>此系统主要针对山区、事故多发地、桥梁等重点区域监测，户外偏远无法通电地区可安装风光发电系统为主机运行发电，同时可安装视频智能监测，当发生护栏撞击安全事故时，系统在定位报警的同时可智能捕捉视频撞击画面。警报信息经过无线传输或公路现有通讯光缆发送到机房/集中监控平台，管理人员可在机房同步查看事故现场信息，便于统一调度。</p>
            <h5>监测平台显示</h5>
            <p>根据现场所提供的GIS地图，导入到软件中，直观的展示现场光纤走向。</p>
            <p>报警时，会自动弹出一个报警窗口。</p>
            <p>精确定位报警位置、报警时间</p>
            <p>振动信号显示、故障诊断报警</p>
            <p>排除干扰信号、防区报警事件处理</p>
          </div>
          <div class="col-lg-7 col-md-7">
            <img :src="require('@/assets/images/fs/ia-04-5.png')" alt="">
          </div>
        </div>
      </div>
    </div>
    <Separator/>
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-5">
            <h3>乡村公路护栏监测</h3>
            <h5>监测系统</h5>
            <p>护栏也是乡村公路，尤其是山区道路的重要安全设施，但在缺少视频监控，机房、供电、通讯资源缺乏的条件下，护栏撞击事故更难及时发现。
              采用安装在户外机箱的低功耗小型主机，可直接无线通讯，太阳能供电，从而实现护栏的无死角实时定位监测。</p>
            <p>由于监测区段和警报参数可无限制调整，对于拥有多段不连续护栏的道路，在量程范围内可用一根连续光缆同时监测所有护栏。</p>
          </div>
          <div class="col-lg-5 col-md-5 offset-lg-2 offset-md-2">
            <h5>监测平台显示</h5>
            <p>根据现场所提供的GIS地图，导入到软件中，直观的展示现场光纤走向。</p>
            <p>报警时，会自动弹出一个报警窗口。</p>
            <p>精确定位报警位置、报警时间</p>
            <p>振动信号显示、故障诊断报警</p>
            <p>排除干扰信号、防区报警事件处理</p>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <img :src="require('@/assets/images/fs/ia-04-7.png')" alt="">
          </div>
        </div>
      </div>
    </div>
    <Separator/>
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-5">
            <h3>公路山体落石监测</h3>
            <h5>监测系统</h5>
            <p>
              监测光缆以”S”形铺设在山体防护栏上，其一端连接到户外箱内的监测主机，利用分布式光纤振动系统对山体防护栏的振动情况进行全天候24小时实时在线监测，当山体出现落石，防护网在拦截落石的同时，系统捕捉到振动信号，同时精准定位事故位置。
              网络联动：视频监控自动捕捉防护网上的落石画面；道路的LED警示牌提示“前有落石，小心通行”
            </p>
            <h5>监测平台显示</h5>
            <p>报警：落石、滑坡振动报警，能第一事件精确定位现场实际地理位置</p>
            <p>视频联动：通过点击报警设备现场视频摄像头，可实时查看现场视频监控画面。</p>
          </div>
          <div class="col-lg-7 col-md-7">
            <img :src="require('@/assets/images/fs/ia-04-8.png')" alt="">
          </div>
        </div>
        <hr>
<!--        <div class="row">-->
<!--          <div class="col-lg-5 col-md-5">-->

<!--          </div>-->
<!--          <div class="col-lg-7 col-md-7">-->
<!--            <img :src="require('@/assets/images/fs/ia-04-9.jpg')" alt="">-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from "@/components/elements/accordion/FSAccordion";
import Separator from "@/components/elements/separator/Separator";

export default {
  name: "IA4",
  components: {Separator, Accordion},
}
</script>
