<template>
  <div :class="`rn-accordion-style ${accordionStyleClass} accordion`">
    <div class="accordion" :id="id">
      <div class="accordion-item card" v-for="(content, index) in accordionContent">
        <h2 class="accordion-header card-header" :id="`accordion-heading${content.id}`">
          <button class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#accordion-collapse${content.id}`"
                  :aria-expanded="content.id === activeCollapse ? 'true' : 'false'"
                  :aria-controls="`accordion-collapse${content.id}`">
            {{ content.title }}
            <i class="collapse-icon"/>
          </button>
        </h2>
        <div :id="`accordion-collapse${content.id}`"
             class="accordion-collapse collapse"
             :class="{'show': content.id === activeCollapse}"
             :aria-labelledby="`accordion-heading${content.id}`"
             :data-bs-parent="`#${id}`">
          <div class="accordion-body card-body">
            <ul class="list-icon">
              <li v-for="menu in content.submenus">
                <Icon name="check" size="20" icon-class="icon"/>
                <Button :read-more-button="true" :url="content.url">
                  <span v-html="menu"></span>
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/icon/Icon";
import Button from "@/components/elements/button/Button";

export default {
  name: 'FSAccordion',
  data () {
    return {
      activeCollapse2: 1
    }
  },
  props: {
    id: {
      type: String,
      default: 'accordionExample'
    },
    accordionStyleClass: {
      type: String
    },
    activeCollapse: {
      type: Number,
      default: 0
    },
    accordionContent: {
      type: Array,
      default: function () {
        return [
          {
            id: 1,
            title: 'What is Doob ? How does it work?',
            submenus: [
              {}
            ]
          },
        ]
      }
    }
  },
  components: {Icon, Button},
  watch: {
    activeCollapse (v) {
      this.activeCollapse2 = v
    }
  }
}
</script>
