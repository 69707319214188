<template>
  <Layout header-class="header-left-align" nav-alignment="left" :header-transparency="true" :show-top-header="false"
          :header-type="2" :footer-style="4">
    <div class="slider-area slider-style-1 variation-default height-850 bg_image"
         :style="{'background-image': `url(${require(`@/assets/images/fs/download-banner.jpg`)})`}">
    </div>
    <Separator/>
    <div class="rn-section-gap" style="padding: 60px 0 !important;" v-for="d in downloads">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 mb-5 col-lg-12">
            <h3 class="text-center">{{ d.title }}</h3>
          </div>
          <div class="col-lg-4 downloadText col-sm-24">
              <div
                   :style="{'background-image': `url(${require(`@/assets/images/fs/ResourceDownload${d.images}.png`)})`,'width':'100%','height':'340px','background-repeat':'no-repeat'}">
              </div>
            </div>
          <div class="col-lg-4 downloadText  col-sm-24">
              <h4>安卓端下载</h4>
              <div class="mb-5">
                <span>路径一：</span>
                <a :href="d.android" target="_blank" class="btn btn-outline-primary btn-lg ">点击下载</a>
                <div>路径二：扫码下载</div>
              </div>
              <div class="mb-5">
                <vue-qr :text="d.android" :size="200" :correctLevel="3" class="text-center"></vue-qr>
              </div>
              <p class="col-8 small" style="font-size: 10px">备注：如果用百度等其他浏览器APP下载，文件可能会被系统自动打包，需要更改后缀名“.apk”</p>
            </div>
          <div class="col-lg-4 downloadText col-sm-24">
              <h4>苹果端下载</h4>
              <div class="mb-5">
                <span>路径一：</span>
                <a :href="d.ios" target="_blank" class="btn btn-outline-primary btn-lg ">点击下载</a>
                <div>路径二：扫码下载</div>
              </div>
              <div class="mb-5">
                <vue-qr :text="d.ios" :size="200" :correctLevel="3" class="text-center"></vue-qr>
              </div>
            </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>

import Layout from "@/components/common/Layout";
import Separator from "@/components/elements/separator/Separator";
import Button from "@/components/elements/button/Button";
import VueQr from 'vue-qr'

export default {
  name: "ResourceDownload",
  data () {
    return {
      downloads: [
        {
          title: '光传输线路智能运维仪APP端下载',
          android: this.getIOMIOLAndroidDownload(),
          images: '1',
          ios: 'https://apps.apple.com/cn/app/%E5%85%89%E4%BC%A0%E8%BE%93%E7%BA%BF%E8%B7%AF%E6%99%BA%E8%83%BD%E8%BF%90%E7%BB%B4%E4%BB%AA-%E8%BF%9C%E7%A8%8B%E6%8E%A7%E5%88%B6%E7%AB%AF/id1558075461'
        },
        {
          title: '小型低功耗光纤振动智能监测系统APP端下载',
          android: this.getOnlineClientAndroidDownload(),
          images: '2',
          ios: 'https://apps.apple.com/us/app/%E5%88%86%E5%B8%83%E5%BC%8F%E5%85%89%E7%BA%A4%E6%8C%AF%E5%8A%A8%E6%99%BA%E8%83%BD%E9%A2%84%E8%AD%A6%E4%B8%BB%E6%9C%BA-%E8%BF%9C%E7%A8%8B%E6%8E%A7%E5%88%B6%E7%AB%AF/id6443912521'
        },
      ]
    }
  },
  methods: {
    getIOMIOLAndroidDownload(){
      if (location.origin.startsWith('https://') || location.origin.endsWith('com')){
        return "https://www.furthersense.com/api/v1/upgrade/download/portable-otdr-client-1.3.5/latest"
      }
      return "http://res.furthersense.cn/api/v1/upgrade/download/portable-otdr-client-1.3.5/latest"
    },
    getOnlineClientAndroidDownload(){
      if (location.origin.startsWith('https://') || location.origin.endsWith('com')){
        return "https://www.furthersense.com/api/v1/upgrade/download/online-client/latest"
      }
      return "http://res.furthersense.cn/api/v1/upgrade/download/online-client/latest"
    }
  },
  components: {
    Layout, Separator, Button, VueQr
  }
}
</script>

<style scoped>
.downloadText{
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
