import { render, staticRenderFns } from "./ResourceDownload.vue?vue&type=template&id=556ce86c&scoped=true&"
import script from "./ResourceDownload.vue?vue&type=script&lang=js&"
export * from "./ResourceDownload.vue?vue&type=script&lang=js&"
import style0 from "./ResourceDownload.vue?vue&type=style&index=0&id=556ce86c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "556ce86c",
  null
  
)

export default component.exports