<template>
  <div class="wrapper">
    <div class="title"><b>光传输线路智能运维仪-远程控制端用户使用协议</b></div>
    <p><strong>1.特别提示</strong></p>
    <p class="mt-5">
      1.1为使用本手机应用软件及服务，您应当阅读并遵守《光传输线路智能运维仪-远程控制端用户使用协议》（以下简称“本协议”）。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款，以及同意或使用某项服务的单独协议，并选择接受或不接受。
    </p>
    <p>
      1.2
      除非您已阅读并接受本协议所有条款，否则您无权下载、安装或使用本软件及相关服务。您的下载、安装、使用、获取账号、登录等行为即视为您已阅读并同意上述协议的约束。
    </p>
    <p>
      1.3无锡远知科技发展有限公司（以下称“本公司”）同意按照本协议的规定及其不时发布的操作规则提供基于互联网的相关服务（以下称"本服务"）。若您需要获得本服务，您（以下称"用户"）应当同意本协议的全部条款并按照页面上的提示完成全部的申请程序。
    </p>
    <p><strong>2.协议的范围</strong></p>
    <p>
      本协议是用户与本公司之间关于用户下载、安装、使用、复制本软件，以及使用本公司相关服务所订立的协议。
    </p>
    <p><strong>3.服务内容与授权使用范围</strong></p>
    <p>
      3.1本软件根据用户实际需求提供服务，例如智能OTDR、快速寻缆、故障定位、路由标定、数据管理，系统设置等。本公司保留随时变更、中断或终止部分或全部本服务的权利。
    </p>
    <p>3.2本软件手机应用的授权使用范围：</p>
    <p>1) 用户可以在手机上安装、使用、显示、运行本软件。</p>
    <p>2) 保留权利：未明示授权的其他一切权利均由本公司所有。</p>
    <p><strong>4.使用规则</strong></p>
    <p>4.1账号使用规则</p>
    <p>
      4.1.1用户在使用本软件前需要注册一个账号。如因注册信息不准确而引起的问题由用户本人承担，本公司不负任何责任并有权暂停或终止用户的账号。
    </p>
    <p>
      4.1.2用户承担账号与密码的保管责任，用户对以其账号发生的或通过其账号发生的一切活动和事件（包括但不限于用户发表的任何内容以及由此产生的任何结果）负全部法律责任。用户须重视账号与密码的保护，若因为用户自身原因，而导致账号与密码遭他人非法使用时，本公司将不承担任何责任。用户自身原因包括但不限于：任意向第三者透露账号和密码及所有注册资料；多人共享同一个账号；安装非法或来路不明的程序等。
    </p>
    <p>4.2用户使用规则</p>
    <p>4.2.1用户在使用本软件时，必须遵循以下原则：</p>
    <p>1) 遵守中国有关的法律和法规；</p>
    <p>2) 不得为任何非法目的而使用本服务系统；</p>
    <p>3) 遵守所有与本服务有关的网络协议、规定和程序；</p>
    <p>
      4) 不得利用本软件系统进行任何可能对互联网的正常运转造成不利影响的行为；
    </p>
    <p>5) 不得利用本软件服务系统进行任何不利于其他用户的行为；</p>
    <p>
      6) 不使用外挂或者其他作弊手段、不正当或不公平手段参加本软件提供的本服务。
    </p>
    <p>
      4.2.2本公司致力于为用户提供文明健康、规范有序的服务，用户应遵守中华人民共和国相关法律法规(如果用户是中华人民共和国境外的使用者，还应遵守所属国家或地区的法律法规)，用户将自行承担用户所发布的信息内容的责任。特别地，用户不得发布下列内容：
    </p>
    <p>1) 反对中华人民共和国宪法所确定的基本原则的；</p>
    <p>2) 危害国家统一、主权和领土完整的；</p>
    <p>3) 泄露国家秘密，危害国家安全或者损害国家荣誉和利益的；</p>
    <p>4) 煽动民族仇恨、民族歧视，破坏民族团结或者侵害民族风俗、习惯的；</p>
    <p>5) 破坏国家宗教政策，宣扬邪教、迷信的；</p>
    <p>6) 散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
    <p>7) 散布淫秽、赌博、暴力或者教唆犯罪的；</p>
    <p>8) 侮辱或者诽谤他人，侵害他人合法权益的；</p>
    <p>9) 危害社会公德或者民族优秀文化传统的；</p>
    <p>10) 宣扬外挂,私服以及木马的相关内容；</p>
    <p>
      11)
      刊登任何经本公司合理判断为不妥当或者本公司未认可的软件、文件等在内的主页地址或者链接的行为；
    </p>
    <p>12) 含有中华人民共和国法律、行政法规禁止的其他内容的。</p>
    <p><strong>5.知识产权</strong></p>
    <p>
      5.1本公司是本软件的知识产权权利人。本软件的一切著作权、商标权、专利权、商业秘密等知识产权，以及与本软件相关的所有信息内容均受中华人民共和国法律法规和相应的国际条约保护，本公司享有上述知识产权，除非事先经本公司的合法授权，任何人皆不得擅自以任何形式使用，否则本公司可立即终止向该用户提供产品和服务，并依法追究其法律责任，赔偿本公司的一切损失。
    </p>
    <p>
      5.2
      用户只有在获得本公司或其他相关权利人的授权之后才能使用这些内容，而不能擅自复制、再造这些内容、或创造与内容有关的派生产品。
    </p>
    <p>
      5.3用户在本软件上发布的信息不得侵犯任何第三人的知识产权，未经相关权利人之事先书面同意，用户不得以任何方式上传、发布、修改、传播或复制任何受著作权保护的材料、商标或属于其他人的专有信息。
    </p>
    <p><strong>6.隐私保护</strong></p>
    <p>
      6.1
      保护用户隐私是本软件的一项基本政策，本公司保证未经用户同意不对外公开或向第三方提供用户申请资料及用户在使用本服务时存储在本软件的个人信息，但下列情况除外：
    </p>
    <p>1) 事先获得用户的明确授权；</p>
    <p>2) 根据有关的法律法规要求；</p>
    <p>3) 按照相关政府主管部门的要求；</p>
    <p>4) 为维护社会公众的利益；</p>
    <p>5) 为维护本软件手机应用的合法权益。</p>
    <p>
      6.2本软件可能会与第三方合作向用户提供相关的本服务，在此情况下，如该第三方同意承担与本公司同等的保护用户隐私的责任，则本公司可将用户的个人信息等提供给该第三方。
    </p>
    <p><strong>7.免责声明</strong></p>
    <p>
      7.1
      对于本软件手机应用的产品和服务，本公司仅作下述有限保证，该有限保证取代任何文档、包装、或其他资料中的任何其他明示或默示的保证(如果有)。本公司仅以"现有状况且包含所有错误"的形式提供相关的产品、软件或程序及任何支持服务，并仅保证：
    </p>
    <p>
      1)
      本软件手机应用所提供的产品和服务能基本符合本软件手机应用正式公布的要求；
    </p>
    <p>
      2)
      本软件手机应用所提供的相关产品和服务基本与本软件手机应用正式公布的服务承诺相符；
    </p>
    <p>
      3)
      本软件手机应用仅在商业上允许的合理范围内尽力解决本软件手机应用在提供产品和服务过程中所遇到的任何问题。
    </p>
    <p>
      7.2
      在适用法律允许的最大范围内，本公司明确表示不提供任何其他类型的保证，不论是明示的或默示的，包括但不限于本软件的适销性、适用性、可靠性、准确性、完整性、无病毒以及无错误的任何明示或默示保证和责任。
    </p>
    <p>
      7.3
      在适用法律允许的最大范围内，本公司并不担保本软件所提供的产品和服务一定能满足用户的要求，也不担保提供的产品和服务不会被中断，并且对产品和服务的及时性，安全性，出错发生，以及信息是否能准确，及时，顺利的传送均不作任何担保。
    </p>
    <p>
      7.4
      在适用法律允许的最大范围内，本公司不就因用户使用本软件的产品和服务引起的，或在任何方面与本软件的产品和服务有关的任何意外的、非直接的、特殊的、或间接的损害或请求(包括但不限于因人身伤害、因隐私泄漏、因未能履行包括诚信或合理谨慎在内的任何责任、因过失和因任何其他金钱上的损失或其他损失而造成的损害赔偿)承担任何责任。
    </p>
    <p>
      7.5本公司保留采取包括但不限于合并服务器等形式以达到服务器资源优化利用的权利，并对由此而可能导致的用户利益损失不承担责任。
    </p>
    <p>
      7.6
      用户在本软件手机应用上所表达的观点、建议等内容均为用户本人看法，不代表本公司及本软件官方的观点。对于用户违法或者违反本协议的使用而引起的一切责任，由用户负全部责任，一概与本公司无关，导致本公司损失的，本公司有权要求用户赔偿，并有权立即停止向其提供服务。
    </p>
    <p>
      7.7
      用户经由本软件本服务与广告商进行通讯联系或商业往来或参与促销活动，完全属于用户与广告商之间的行为，与本公司没有任何关系，若因商业行为所产生之任何损害或损失，本公司不承担任何责任。
    </p>
    <p><strong>8.服务变更、中断或终止</strong></p>
    <p>8.1软件更新</p>
    <p>
      8.1.1为了改善用户体验、完善服务内容，本公司将不断努力开发新的服务，并为用户不时提供软件更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）。
    </p>
    <p>
      8.1.2为了保证本软件及服务的安全性和功能的一致性，本公司有权不经向用户特别通知而对软件进行更新，或者对软件的部分功能效果进行改变或限制。本软件新版本发布后，旧版本的软件可能无法使用。本公司不保证旧版本软件继续可用及相应的客户服务，请用户随时核对并下载最新版本。
    </p>
    <p>
      8.2
      为了软件的正常运行，本软件手机应用需要定期或不定期地对软件进行停机维护，因此类情况而造成的正常服务中断、停止，用户应该予以理解，本公司应尽力避免服务中断、停止或将中断、停止时间限制在最短时间内。
    </p>
    <p>8.3 服务中断或终止</p>
    <p>
      8.3.1如发生下列任何一种情形，本公司有权随时中断或终止向用户提供本协议项下的本服务而无需通知用户，对因此而产生的不便或损害，本公司对用户或第三人均不承担任何责任：
    </p>
    <p>
      1)
      定期检查或施工，更新软硬件等，本公司有权暂停服务，但我们会尽快完成维护、更新工作；
    </p>
    <p>2) 服务器遭受损坏，无法正常运作；</p>
    <p>3) 突发性的软硬件设备与电子通信设备故障；</p>
    <p>4) 网络提供商线路或其它故障；</p>
    <p>5) 在紧急情况之下为维护国家安全或其它用户及第三者之人身安全；</p>
    <p>6) 地震等不可抗力及其他第三方原因造成服务瘫痪、中断或丢失。</p>
    <p><strong>9.违约责任</strong></p>
    <p>
      9.1
      如果本公司发现用户有下列任一行为的，有权根据相应本服务的公约或守则的规定，采取相应措施：包括但不限于对该用户账号的冻结、终止、删除；用户在此承诺本公司有权作出上述行为，并承诺不就上述行为要求本公司做任何补偿或退费：
    </p>
    <p>1) 用户违反本协议中规定的使用规则；</p>
    <p>
      2)
      通过非法手段、不正当手段或其他不公平的手段使用本软件的产品和服务或参与本软件活动；
    </p>
    <p>3) 有损害本软件正当利益的行为；</p>
    <p>4) 有严重损害其他用户的行为；</p>
    <p>5) 有违反中华人民共和国的法律、法规的行为或言论；</p>
    <p>6) 有违背社会风俗、社会道德和互联网一般道德和礼仪的行为；</p>
    <p>
      7) 其他妨碍本软件提供产品和服务或本公司认为的严重不当行为；
      同一用户若有任一账号存在上述任一行为的，本公司有权对该用户下的所有账号予以制裁，包括但不限于冻结账号、删除账号、终止服务等。本公司因上述原因删除用户注册的账号后即不再对用户承担任何义务和责任。
    </p>
    <p>
      9.2
      用户同意保障和维护本软件及其他用户的利益，如因用户违反有关法律、法规或本协议项下的任何条款而给本软件或任何其他第三人造成损失，用户同意承担由此造成的损害赔偿责任。
    </p>
    <p><strong>10.改协议</strong></p>
    <p>
      10.1本公司将可能不时的修改本协议的有关条款，一旦条款内容发生变动，本公司将会在相关的页面提示修改内容。
    </p>
    <p>
      110.2
      如果用户不同意本公司修改的内容，用户可以主动取消获得的本服务。如果用户在修改内容公告后15天内未主动取消服务，则视为接受条款的变更；修改内容公告后用户如果仍继续使用本软件提供的产品和服务亦构成对条款变更的接受。
    </p>
    <p><strong>11.法律管辖</strong></p>
    <p>11.1 本协议的订立、执行和解释及争议的解决均应适用中国法律。</p>
    <p>
      11.2
      如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，用户和本公司一致同意交由软件运营商所在地有管辖权的法院管辖。
    </p>
    <p><strong>12.通知和送达</strong></p>
    <p>
      12.1
      本协议项下所有的通知均可通过重要页面公告、电子邮件或常规的信件传送等方式进行；该通知于发送之日视为已送达收件人。
    </p>
    <p><strong>13.其他规定</strong></p>
    <p>13.1用户接受本服务条款视为用户已充分理解并同意：</p>
    <p>
      本公司依法保障用户在安装或使用过程中的知情权和选择权，在用户使用本公司服务过程中，涉及用户设备自带功能的服务会提前征得用户同意。用户一经确认，本公司有权开启包括但不限于收集地理位置、获取手机信息、使用摄像头、Storage读写权限等提供服务必要的辅助功能。
    </p>
    <p>
      13.2
      本协议构成双方对本协议之约定事项及其他有关事宜的完整协议，除本协议规定的之外，未赋予本协议各方其他权利。
    </p>
    <p>
      13.3
      如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。
    </p>
    <p>13.4 本协议中的标题仅为方便而设，在解释本协议时应被忽略。</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.wrapper {
  color: #69797e;
  font-size: 1.2rem;
  font-weight: 500;
  // line-height: 1.3rem;
  padding-bottom: 20px;

  .title {
    padding: 40px 20px 20px 20px;
    font-size: 2.2rem;
    // line-height: 2.3rem;
    font-weight: bold;
    text-align: center;
    line-break: auto;
    text-indent: 0;
  }

  p {
    margin: 20px;
    font-size: 1.2rem;
    font-weight: 500;
    // line-height: 1.3rem;
    text-indent: 2em;
  }

  b {
    font-weight: 700;
    color: #000;
  }

  table {
    width: 95%;
    margin-left: 10px;
    text-align: center;
    border-collapse: collapse;

    & tr td {
      border: 1px solid #a3abae;
    }
  }
  .underline {
    text-decoration: underline;
  }

  .explain {
    // height: 40px;
    font-size: 1.4rem;
    // font-size: 24px;
    // line-height: 40px;
    color: #a3abae;
    text-align: center;
    background-color: #efeff4;
  }
}
</style>
