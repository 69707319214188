<template>
  <router-view/>
</template>

<script>
import AppFunctions from "@/helpers/AppFunctions";

export default {
  created () {
    if (this.$route.name !== 'map-viewer') {
      AppFunctions.addScrollEventListener()
    }
  },
  mounted () {
    if (this.$route.name !== 'map-viewer') {
      AppFunctions.toggleStickyHeader();
    }
  },
  beforeDestroy () {
    if (this.$route.name !== 'map-viewer') {
      AppFunctions.removeScrollEventListener();
    }
  }
}
</script>
