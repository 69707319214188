<template>
  <!-- Start Copy Right Area  -->
  <div class="copyright-area copyright-style-one">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="copyright-right text-center text-lg-end">
            <p class="copyright-text">
<!--              <a target="_blank" href="https://xyt.xcc.cn/getpcInfo?sn=1580070442335387648&certType=8&url=www.furthersense.com&language=CN">-->
<!--                <embed src="https://program.xinchacha.com/web/1580070442335387648=www.furthersense.com.svg" width="103" height="38" type="image/svg+xml" pluginspage="//www.adobe.com/svg/viewer/install/"/>-->
<!--              </a>-->
              <a target="_blank" href="https://xyt.xcc.cn/getpcInfo?sn=1580070442335387648&certType=8&url=www.furthersense.com&language=CN">
                <img src="https://program.xinchacha.com/xyt/xcc_small_ssl.png" style="width:30px;height:30px" alt="ssl">
              </a>
              © 版权所有 {{ new Date().getFullYear() }}<sup>{{getVersion()}}</sup>
              <a class="p-3" href="#">无锡远知科技发展有限公司</a>
              <a href="https://beian.miit.gov.cn">{{ getRegistration() }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Copy Right Area  -->
</template>

<script>
import pkg from '../../../../package.json';
export default {
  name: 'FSCopyright',
  methods: {
    getVersion(){
      return pkg.version
    },
    getRegistration() {
      let registration = '苏ICP备2022029774号'
      if (location.origin.endsWith('com')) {
        return registration + '-2'
      }
      return registration + '-1'
    }
  }
}
</script>
