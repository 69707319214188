<template>
  <div :class="{'header-transparent-with-topbar': data.headerTransparency}">
    <!-- Start Header Area -->
    <header :class="[`rn-header header-default header-not-transparent header-sticky header-bg-change ${data.headerClass}`]">
      <div class="container position-relative">
        <div class="row align-items-center row--0">
          <div class="col-lg-9 col-md-6 col-4 position-static">
            <div class="header-left d-flex">
              <Logo :light-logo="require('@/assets/images/fs/header-logo.png')"
                    :dark-logo="require('@/assets/images/fs/header-logo2.png')"/>
              <nav class="mainmenu-nav d-none d-lg-block">
                <Nav/>
              </nav>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-8">
            <div class="header-right">
              <div class="d-flex" style="max-width: 180px">
                <Logo class="d-lg-block d-md-block d-none"
                      :light-logo="require('@/assets/images/fs/header-tel.png')"
                      :dark-logo="require('@/assets/images/fs/header-tel.png')"/>
              </div>
              <!-- Start Mobile-Menu-Bar -->
              <div class="mobile-menu-bar ml--5 d-block d-lg-none">
                <div class="hamberger">
                  <button class="hamberger-button"
                          @click.prevent="AppFunctions.toggleClass('.popup-mobile-menu', 'active')">
                    <Icon name="menu" size="21"/>
                  </button>
                </div>
              </div>
              <!-- Start Mobile-Menu-Bar -->
              <div v-if="data.showThemeSwitcherButton" id="my_switcher" class="my_switcher">
                <ul>
                  <li>
                    <a href="javascript: void(0);"
                       @click.prevent="AppFunctions.toggleClass('body', 'active-light-mode')">
                      <img class="light-icon"
                           src="../../../../assets/images/icons/sun-01.svg"
                           alt="Sun images">
                      <img class="dark-icon"
                           src="../../../../assets/images/icons/vector.svg"
                           alt="Moon Images">
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- End Header Area -->

    <!-- Start Mobile Menu -->
    <MobileMenu/>
    <!-- End Mobile Menu -->
  </div>
</template>

<script>
import MobileMenu from './MobileMenu'
import Nav from './Nav'
import Logo from "@/components/elements/logo/Logo";
import AppFunctions from "@/helpers/AppFunctions";
import Icon from "@/components/icon/Icon";

export default {
  name: 'Header',
  props: {
    data: {
      default: null
    }
  },
  components: {Logo, Nav, MobileMenu, Icon},
  data () {
    return {
      mode: 1,
      AppFunctions
    }
  },
}
</script>
<style scoped>
header{
  border: none;
}
.active-light-mode  .header-bg-change {
  /*background-color: rgba(255, 255, 255, 0.7) !important;*/
  color: black !important;
}
</style>
