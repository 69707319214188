<template>
  <div class="ia3">
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 mb-5">
            <h3>监测领域</h3>
            <slot/>
          </div>
          <div class="col-lg-8 col-md-6 col-sm-12 col-12">
            <div class="row mb-5">
              <img :src="require(`@/assets/images/fs/ia-03-1.jpg`)" alt="电力领域智能安全监测" title="电力领域智能安全监测">
            </div>
            <div class="row">
              <h3 class="text-center">行业前景</h3>
              <p class="description">
                电力电缆（以下简称电缆）是城市运行的命脉，而且电缆普遍存在城市的每一个角落，野蛮施工、挖掘、火灾、过度负荷等都有可能对电缆造成伤害，电缆一旦被破坏，势必造成电力中断，且电缆多埋于地下，也没有自动定位的功能，所以寻找起来十分困难，往往需要花费数小时，甚至几天的时间，不仅浪费了大量的人力、物力，而且会造成难以估量的经济损失。如何有效的防止电缆被破坏、温度异常点地点以及被破坏后如何精确的定位事故地点便成了供电部门的核心问题。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Separator/>
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-5">
            <h3>电缆重点区域防外破监测</h3>
            <h5>监测系统</h5>
            <p>
              分别利用分布式光纤振动系统对电缆进行全天候24小时实时在线监测，全面的掌握电缆上方的振动信号变化，一旦出现开挖或破坏振动信号，系统可立即产生报警，同时精准定位事故位置。
            </p>
          </div>
          <div class="col-lg-7 col-md-7">
            <img :src="require('@/assets/images/fs/ia-03-2.png')" alt="">
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-lg-5 col-md-5">
            <h5>监测平台显示</h5>
            <p>根据现场所提供的GIS地图，导入到软件中，直观的展示现场光纤走向。</p>
            <p>报警时，会自动弹出一个报警窗口。</p>
            <p>流水号：报警序号</p>
            <p>时间：最新报警时间</p>
            <p>备注：用于报警地理位置信息显示，可在编辑不同的防区时添加附近的建筑物或是桩号等等信息，便于现场查证。</p>
          </div>
          <div class="col-lg-7 col-md-7">
            <img :src="require('@/assets/images/fs/ia-01-9.jpg')" alt="">
          </div>
        </div>
      </div>
    </div>
    <Separator/>
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-5">
            <h3>电缆及电缆通道温度监测</h3>
            <h5>监测系统</h5>
            <p>单端接入测温光缆，24小时实时全线温度监测；</p>
            <p>电缆本体测温，精准定位电缆接头等局部过热点；</p>
            <p>电缆夹层测温，光缆S型铺设，精准定位夹层局部过热及消防隐患。</p>
            <p>利用电缆通道已有光缆一芯，掌握通道全线温度，定位局部火灾。</p>
            <h5>监测平台显示</h5>
            <p>温度实时显示：系统可以实时显示各通道、各分区温度变化。</p>
            <p>温度曲线：系统可通过温度曲线变化查询各分区的温度变化现状及历史温度曲线变化；</p>
            <p>温度预测：对监测对象的温度变化趋势进行智能分析、预测。</p>
          </div>
          <div class="col-lg-7 col-md-7">
            <img :src="require('@/assets/images/fs/ia-03-4.jpg')" alt="">
          </div>
        </div>
        <hr>
      </div>
    </div>
    <Separator/>
  </div>
</template>

<script>
import Accordion from "@/components/elements/accordion/FSAccordion";
import Separator from "@/components/elements/separator/Separator";

export default {
  name: "IA3",
  components: {Separator, Accordion},
}
</script>
