<template>
  <Layout header-class="header-left-align" nav-alignment="left" :header-transparency="true" :show-top-header="false"
          :header-type="2" :footer-style="4">
    <div class="slider-area slider-style-1 variation-default height-850 bg_image"
         :style="{'background-image': `url(${require(`@/assets/images/fs/scBanner.jpg`)})`}">
    </div>
    <Separator/>
    <SC01 v-if="getActiveCollapse() === 1">
      <Accordion :id="`IMAccordion${getActiveCollapse()}`"
                 accordion-style-class="rn-accordion-04"
                 :accordion-content="accordionContent"
                 :active-collapse="getActiveCollapse()"
      />
    </SC01>
  </Layout>
</template>
<script>
import Layout from "@/components/common/Layout";
import Separator from "@/components/elements/separator/Separator";
import Accordion from "@/components/elements/accordion/FSCaseAccordion";
import SC01 from "@/views/fs/success-cases/SC01";

export default {
  name: "IndustryApplication",
  components: {
    SC01,
    Layout, Separator, Accordion
  },
  data () {
    return {
      accordionContent: [
        {
          id: 1,
          title: '通讯领域案例展示',
          submenus: [
            {title: '河南电信长线局光缆状态监测', url: `/success-cases/1?title=communication1`},
            {title: '桂林移动光缆状态监测', url: `/success-cases/1?title=communication2`},
            {title: '长春移动光缆路由标定', url: `/success-cases/1?title=communication3`},
            {title: '徐州移动光缆路由、分缆测试', url: `/success-cases/1?title=communication4`},
          ]
        },
        {
          id: 2,
          title: '石化领域案例展示',
          submenus: [
              {title: '国家管网北方公司锦州分公司光缆隐患整治', url: `/success-cases/1?title=petrifaction1`},
              {title: '国家管网北方公司管道光纤状态监测', url: `/success-cases/1?title=petrifaction2`},
              {title: '国家管网西南公司管道光纤故障监测', url: `/success-cases/1?title=petrifaction3`},
          ]
        },
        {
          id: 3,
          title: '电力领域案例展示',
          submenus: [
            {title: '杭州电力电缆通道外力破坏预警定位监测', url: `/success-cases/1?title=power1`},
            {title: '郑州国网光纤预警系统项目', url: `/success-cases/1?title=power2`},
            {title: '河南电力电缆通道外力破坏预警定位监测', url: `/success-cases/1?title=power3`},
            {title: '广东电力电缆通道外力破坏预警定位监测', url: `/success-cases/1?title=power4`},
            {title: '浙江某国网电力电缆通道外力破坏预警定位监测', url: `/success-cases/1?title=power5`},
          ]
        },
        {
          id: 4,
          title: '交通领域案例展示',
          submenus: [
            {title: '成都铁路局通信光缆路由标定', url: `/success-cases/1?title=traffic1`},
          ]
        },
        {
          id: 5,
          title: '国防领域案例展示',
          submenus: [
              {title: '国防光缆路由标定、在线状态定位监测', url: `/success-cases/1?title=national1`},
          ]
        },
      ]
    }
  },
  methods: {
    getActiveCollapse () {
      return Number(this.$route.params.id)
    }
  },
}
</script>

<style scoped>

</style>
