import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from "@/views/fs/Index";
import AboutUs from "@/views/fs/AboutUs";
import AboutHide from "@/views/fs/AboutHide";
import AppAgreement from "@/views/fs/AppAgreement";
import ProductIntroduction from "@/views/fs/ProductIntroduction";
import IndustryApplication from "@/views/fs/IndustryApplication";
import SuccessCases from "@/views/fs/SuccessCases";
import ResourceDownload from "@/views/fs/ResourceDownload";
import MapViewer from "@/views/fs/MapViewer";
import {f} from "core-js/internals/new-promise-capability";
import OTDRAgreement from "../views/fs/OTDRAgreement";
import OTDRPrivacy from "../views/fs/OTDRPrivacy";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: Index,
        meta: {
            title: '无锡远知科技发展有限公司'
        }
    },
    {
        path: '/about-us',
        name: 'about-us',
        component: AboutUs,
        meta: {
            title: '关于我们|无锡远知科技发展有限公司'
        }
    },
    {
        path: '/about-hide',
        name: 'about-hide',
        component: AboutHide,
        meta: {
            title: '隐私政策|无锡远知科技发展有限公司'
        }
    },
    {
        path: '/app-agreement',
        name: 'app-agreement',
        component: AppAgreement,
        meta: {
            title: '运维仪隐私政策|无锡远知科技发展有限公司'
        }
    },
    {
        path: '/app/otd-agreement',
        name: 'otdr-agreement',
        component: OTDRAgreement,
        meta: {
            title: '运维仪用户协议|无锡远知科技发展有限公司'
        }
    },
    {
        path: '/app/otd-privacy',
        name: 'otdr-privacy',
        component: OTDRPrivacy,
        meta: {
            title: '运维仪隐私政策|无锡远知科技发展有限公司'
        }
    },
    {
        path: '/product-introduction/:id',
        name: 'product-introduction',
        component: ProductIntroduction,
        meta: {
            title: '产品介绍|无锡远知科技发展有限公司'
        }
    },
    {
        path: '/industry-application/:id',
        name: 'industry-application',
        component: IndustryApplication,
        meta: {
            title: '行业应用|无锡远知科技发展有限公司'
        }
    },
    {
        path: '/success-cases/:id',
        name: 'success-cases',
        component: SuccessCases,
        meta: {
            title: '成功案例|无锡远知科技发展有限公司'
        }
    },
    {
        path: '/resource-download',
        name: 'resource-download',
        component: ResourceDownload,
        meta: {
            title: '资源下载|无锡远知科技发展有限公司'
        }
    },
    {
        path: '/map',
        name: 'map-viewer',
        component: MapViewer,
        meta: {
            title: '分享坐标'
        }
    },
    {
        path: '/app/share/map',
        name: 'map-viewer2',
        component: MapViewer,
        meta: {
            title: '分享坐标'
        }
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
