<template>
  <baidu-map class="map" :center="center" :zoom="zoom" :dragging="true" :double-click-zoom="true"
             :scroll-wheel-zoom="true" @ready="handler" @click="onClick"
  >
    <bm-scale></bm-scale>
  </baidu-map>
</template>

<script>
import AppFunctions from "@/helpers/AppFunctions";

export default {
  name: "MapViewer",
  data () {
    return {
      center: {lng: 120.409114, lat: 31.513794},
      zoom: 13,
      name: '无锡',
      address: '坐标点'
    }
  },
  created () {
    document.body.style.height = `${window.innerHeight}px`
  },
  mounted () {
    this.name = AppFunctions.getOrDefault(this.$route.query.name, this.name)
    this.address = AppFunctions.getOrDefault(this.$route.query.address, this.address)
    this.center.lng = AppFunctions.getOrDefault(this.$route.query.lng, AppFunctions.getOrDefault(this.$route.query.longitude, this.center.lng))
    this.center.lat = AppFunctions.getOrDefault(this.$route.query.lat, AppFunctions.getOrDefault(this.$route.query.latitude, this.center.lat))
    this.zoom = AppFunctions.getOrDefault(this.$route.query.zoom, this.zoom)
  },
  methods: {
    handler ({BMap, map}) {
      const point = new BMap.Point(this.center.lng, this.center.lat)
      map.centerAndZoom(point, this.zoom)
      const marker = new BMap.Marker(point, {})
      const label = new BMap.Label(`${this.name}<br>经度: ${this.center.lng}<br>维度: ${this.center.lat}`, {
        title: `${this.name}: ${this.address}`,
        offset: {width: -15, height: 20}
      })
      label.setStyle({border: '1px solid orange', borderRadius: '10px', padding: '5px '})
      marker.setLabel(label);
      marker.setTop(true)
      map.addOverlay(marker)
      /* global BMAP_ANIMATION_BOUNCE */
      marker.setAnimation(BMAP_ANIMATION_BOUNCE)
    },
    onClick (e) {
    }
  }
}
</script>

<style scoped lang="scss">
html {
  body {
    .map {
      height: 100% !important;
      overflow: hidden;
      width: 100%;
      margin: 0;
      font-family: "微软雅黑", serif;
    }
  }
}

</style>
