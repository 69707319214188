<template>
  <Layout header-class="header-left-align" nav-alignment="left" :header-transparency="true" :show-top-header="false"
          :header-type="2" :footer-style="4">
    <div class="slider-area slider-style-1 variation-default height-850 bg_image"
         :style="{'background-image': `url(${require(`@/assets/images/fs/ia-banner.jpg`)})`}">
    </div>
    <Separator/>
    <IA1 v-if="getActiveCollapse() === 1">
      <Accordion :id="`IMAccordion${getActiveCollapse()}`"
                 accordion-style-class="rn-accordion-04"
                 :accordion-content="accordionContent"
                 :active-collapse="getActiveCollapse()"
      />
    </IA1>
    <IA2 v-if="getActiveCollapse() === 2">
      <Accordion :id="`IMAccordion${getActiveCollapse()}`"
                 accordion-style-class="rn-accordion-04"
                 :accordion-content="accordionContent"
                 :active-collapse="getActiveCollapse()"
      />
    </IA2>
    <IA3 v-if="getActiveCollapse() === 3">
      <Accordion :id="`IMAccordion${getActiveCollapse()}`"
                 accordion-style-class="rn-accordion-04"
                 :accordion-content="accordionContent"
                 :active-collapse="getActiveCollapse()"
      />
    </IA3>
    <IA4 v-if="getActiveCollapse() === 4">
      <Accordion :id="`IMAccordion${getActiveCollapse()}`"
                 accordion-style-class="rn-accordion-04"
                 :accordion-content="accordionContent"
                 :active-collapse="getActiveCollapse()"
      />
    </IA4>
    <IA5 v-if="getActiveCollapse() === 5">
      <Accordion :id="`IMAccordion${getActiveCollapse()}`"
                 accordion-style-class="rn-accordion-04"
                 :accordion-content="accordionContent"
                 :active-collapse="getActiveCollapse()"
      />
    </IA5>
  </Layout>
</template>
<script>
import Layout from "@/components/common/Layout";
import Separator from "@/components/elements/separator/Separator";
import Accordion from "@/components/elements/accordion/FSAccordion";
import IA1 from "@/views/fs/industry-application/IA1";
import IA2 from "@/views/fs/industry-application/IA2";
import IA5 from "@/views/fs/industry-application/IA5";
import IA4 from "@/views/fs/industry-application/IA4";
import IA3 from "@/views/fs/industry-application/IA3";
import AccordionContent from '../../helpers/product.json'

export default {
  name: "IndustryApplication",
  components: {
    IA1,
    IA2,
    IA3,
    IA4,
    IA5,
    Layout, Separator, Accordion
  },
  data() {
    return {
      accordionContent: AccordionContent
    }
  },
  methods: {
    getActiveCollapse() {
      return Number(this.$route.params.id)
    }
  },
}
</script>

<style scoped>

</style>
