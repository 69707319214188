<template>
  <div class="sc01">
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12 col-12">
            <h3>成功案例</h3>
            <slot/>
          </div>
          <div class="col-lg-8 col-md-6 col-sm-12 col-12 mt-5">
            <div class="row">
              <div class="col-12">
                <h4 class="text-center">{{ sucTitle }}</h4>
              </div>
              <div class="sucMargin">
                <div class="col-lg-6 col-md-12 col-sm-12">
                  <h5>应用单位：
                    <p class="small p-inline">
                      {{ sucContentOne }}
                    </p>
                  </h5>

                  <h5>监测类别：
                    <p class="small p-inline">
                      {{ sucContentTwo }}
                    </p>
                  </h5>

                  <h5>应用产品：
                    <p class="small p-inline">
                      <router-link v-if="Transporter" to="/product-introduction/1">{{ sucContentThree }}</router-link>
                      <router-link v-if="equipment" to="/product-introduction/3">{{ sucContentThree }}</router-link>
                      <router-link v-if="equipmentS" to="/product-introduction/2">{{ sucContentThree }}</router-link>
                    </p>
                  </h5>

                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 offset-md-1">
                  <router-link v-if="Transporter" to="/product-introduction/1">
                    <img v-if="Transporter" :src="require('@/assets/images/fs/phoneImg.jpg')"
                         alt="IOMIOL-HS40型光传输线路智能运维仪" title="IOMIOL-HS40型光传输线路智能运维仪"/>
                  </router-link>
                  <router-link v-if="equipment" to="/product-introduction/3">
                    <img v-if="equipment" :src="require('@/assets/images/fs/equipmentImg.jpg')"
                         alt="小型低功耗振动智能监测设备" title="小型低功耗振动智能监测设备"/>
                  </router-link>
                  <router-link v-if="equipmentS" to="/product-introduction/2">
                    <img v-if="equipmentS" :src="require('@/assets/images/fs/equipmentS.jpg')"
                         alt="同步多通道分布式光纤振动智能监测设备" title="同步多通道分布式光纤振动智能监测设备"/>
                  </router-link>


                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <Separator/>

    <div class="rn-section-gap" v-if="example">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h3>现场案例照片展示</h3>
          </div>
          <div class="col-12 mt-5 mb-5">

            <!--            <img v-if="$route.query =='success1'" :src="require('@/assets/images/fs/sc-01-2.jpg')" alt=""/>-->
            <img v-if="$route.query.title ==='communication1'" :src="require('@/assets/images/fs/phoneImg1.jpg')"
                 alt=""/>
            <img v-if="$route.query.title ==='communication2'" :src="require('@/assets/images/fs/phoneImg2.jpg')"
                 alt=""/>
            <img v-if="$route.query.title ==='communication3'" :src="require('@/assets/images/fs/phoneImg3.jpg')"
                 alt=""/>
            <img v-if="$route.query.title ==='communication4'" :src="require('@/assets/images/fs/phoneImg4.jpg')"
                 alt=""/>
            <img v-if="$route.query.title ==='petrifaction1'" :src="require('@/assets/images/fs/petrifactionImg1.jpg')"
                 alt=""/>
            <img v-if="$route.query.title ==='petrifaction2'" :src="require('@/assets/images/fs/petrifactionImg2.jpg')"
                 alt=""/>
            <img v-if="$route.query.title ==='petrifaction3'" :src="require('@/assets/images/fs/petrifactionImg3.jpg')"
                 alt=""/>
            <img v-if="$route.query.title ==='power1'" :src="require('@/assets/images/fs/powerImg1.jpg')" alt=""/>
            <img v-if="$route.query.title ==='power2'" :src="require('@/assets/images/fs/powerImg2.jpg')" alt=""/>
            <img v-if="$route.query.title ==='power5'" :src="require('@/assets/images/fs/powerImg5.jpg')" alt=""/>
            <img v-if="$route.query.title ==='traffic1'" :src="require('@/assets/images/fs/traffic1.jpg')" alt=""/>
          </div>
        </div>
      </div>
    </div>
    <Separator/>

    <div class="rn-section-gap" v-if="$route.query.title === 'petrifaction1'">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h3>项目使用效果报告</h3>
          </div>
          <div class="col-12 mt-5 mb-5">
            <img :src="require('@/assets/images/fs/sc-01-3.jpg')" alt=""/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from "@/components/elements/accordion/FSAccordion";
import Separator from "@/components/elements/separator/Separator";

export default {
  name: "SC01",
  components: {Separator, Accordion},
  data() {
    return {
      sucTitle: '河南电信长线局光缆状态监测',
      sucContentOne: '河南电信长线局',
      sucContentTwo: '光纤故障定位，路由标定。',
      sucContentThree: '光传输线路智能运维仪',
      Transporter: true,// 运维仪
      equipment: false, // 小型监测设备
      equipmentS: false, // 同步道监测设备
      example: true,  // 现场实例照片
    }
  },
  created() {
  },
  updated() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      if (this.$route.query.title === 'communication1') {
        this.sucTitle = '河南电信长线局光缆状态监测';
        this.sucContentOne = '河南电信长线局';
        this.sucContentTwo = '光纤故障定位，路由标定。';
        this.sucContentThree = '光传输线路智能运维仪';
        this.Transporter = true
        this.equipment = false
        this.equipmentS = false
        this.example = true
      }
      if (this.$route.query.title === 'communication2') {
        this.sucTitle = '桂林移动光缆状态监测';
        this.sucContentOne = '桂林某移动公司';
        this.sucContentTwo = '光纤故障定位、路由标定、分缆识别。';
        this.sucContentThree = '光传输线路智能运维仪';
        this.Transporter = true
        this.equipment = false
        this.equipmentS = false
        this.example = true
      }
      if (this.$route.query.title === 'communication3') {
        this.sucTitle = '长春移动光缆路由标定';
        this.sucContentOne = '长春某移动公司';
        this.sucContentTwo = '光缆路由标定。';
        this.sucContentThree = '光传输线路智能运维仪';
        this.Transporter = true
        this.equipment = false
        this.equipmentS = false
        this.example = true
      }
      if (this.$route.query.title === 'communication4') {
        this.sucTitle = '徐州移动光缆路由、分缆测试';
        this.sucContentOne = '徐州某移动公司';
        this.sucContentTwo = '光缆路由标定、分缆测试。';
        this.sucContentThree = '光传输线路智能运维仪';
        this.Transporter = true
        this.equipment = false
        this.equipmentS = false
        this.example = true

      }
      if (this.$route.query.title === 'petrifaction1') {
        this.sucTitle = '国家管网北方公司锦州输油气分公司光缆隐患整治';
        this.sucContentOne = '国家管网北方公司锦州分公司';
        this.sucContentTwo = '管道光纤断点故障定位监测。';
        this.sucContentThree = '光传输线路智能运维仪';
        this.Transporter = true
        this.equipment = false
        this.equipmentS = false
        this.example = true
      }
      if (this.$route.query.title === 'petrifaction2') {
        this.sucTitle = '国家管网北方公司管道光纤状态监测';
        this.sucContentOne = '国家管网北方公司';
        this.sucContentTwo = '管道光纤断点故障定位，光纤路由标定，与管道分离情况检测。';
        this.sucContentThree = '光传输线路智能运维仪';
        this.Transporter = true
        this.equipment = false
        this.equipmentS = false
        this.example = true

      }
      if (this.$route.query.title === 'petrifaction3') {
        this.sucTitle = '国家管网西南公司南宁站管道光纤故障监测';
        this.sucContentOne = '国家管网西南公司';
        this.sucContentTwo = '管道光纤故障定位监测。';
        this.sucContentThree = '光传输线路智能运维仪';
        this.Transporter = true
        this.equipment = false
        this.equipmentS = false
        this.example = true
      }
      if (this.$route.query.title === 'power1') {
        this.sucTitle = '杭州电力电缆通道外力破坏预警定位监测';
        this.sucContentOne = '杭州某电力公司';
        this.sucContentTwo = '电缆通道外力破坏预警定位监测。';
        this.sucContentThree = '小型低功耗振动智能监测设备';
        this.equipment = true
        this.Transporter = false
        this.equipmentS = false
        this.example = true
      }
      if (this.$route.query.title === 'power2') {
        this.sucTitle = '郑州国网光纤预警系统项目监测';
        this.sucContentOne = '国网河南某电力公司';
        this.sucContentTwo = '电缆通道外力破坏预警定位监测。';
        this.sucContentThree = '同步多通道分布式光纤振动智能监测设备';
        this.Transporter = false
        this.equipment = false
        this.equipmentS = true
        this.example = true
      }
      if (this.$route.query.title === 'power3') {
        this.sucTitle = '河南电力电缆通道外力破坏预警定位监测';
        this.sucContentOne = '河南某电力公司';
        this.sucContentTwo = '电缆通道外力破坏预警定位监测。';
        this.sucContentThree = '同步多通道分布式光纤振动智能监测设备';
        this.Transporter = false
        this.equipment = false
        this.equipmentS = true
        this.example = false
      }
      if (this.$route.query.title === 'power4') {
        this.sucTitle = '广东电力电缆通道外力破坏预警定位监测';
        this.sucContentOne = '广东电力某供电局';
        this.sucContentTwo = '电缆通道外力破坏预警定位监测。';
        this.sucContentThree = '同步多通道分布式光纤振动智能监测设备';
        this.Transporter = false
        this.equipment = false
        this.equipmentS = true
        this.example = false
      }
      if (this.$route.query.title === 'power5') {
        this.sucTitle = '浙江某国家电网';
        this.sucContentOne = '浙江某国家电网';
        this.sucContentTwo = '电缆通道外力破坏预警定位监测。';
        this.sucContentThree = '小型低功耗振动智能监测设备';
        this.equipment = true
        this.Transporter = false
        this.equipmentS = false
        this.example = true
      }
      if (this.$route.query.title === 'traffic1') {
        this.sucTitle = '成都铁路局通信光缆路由标定';
        this.sucContentOne = '成都铁路局贵阳局';
        this.sucContentTwo = '铁路通信光缆路由标定。';
        this.sucContentThree = '光传输线路智能运维仪';
        this.Transporter = true
        this.equipment = false
        this.equipmentS = false
        this.example = true
      }
      if (this.$route.query.title === 'national1') {
        this.sucTitle = '国防光缆路由标定、在线状态定位监测';
        this.sucContentOne = '某市专线光缆管理系统';
        this.sucContentTwo = '全市专线网的路由标定、在线状态监测预警。';
        this.sucContentThree = '光传输线路智能运维仪、多路在线OTDR监测系统，监控管理平台';
        this.Transporter = true
        this.equipment = false
        this.equipmentS = false
        this.example = false
      }

    }
  }
}
</script>
<style lang="scss">
.small a {
  color: #65676B;
}

.p-inline {
  display: inline !important;
}

.sucMargin {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px !important;
}

@media (max-width: 1000px) {
  .sucMargin {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px !important;
  }
}

.small {
  display: inline-block;
}
</style>
