<template>
  <div class="pi2">

    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title text-center" data-aos="fade-up">
              <h2 class="title w-600">产品简述</h2>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="row" style="align-items: center">
              <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <div class="mt-5">
                  <img :src="require(`@/assets/images/fs/product-0-3.png`)" alt="">
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 offset-lg-1 offset-md-1">
                <div class="mt-5">
                 <p>
                   小型低功耗分布式光纤振动预警系统，基于相位敏感光时域反射（Φ-OTDR）原理，拥有分布式实时监测、高探测灵敏度和高定位精度（±5m）的特点。本系统依托于Arm架构和嵌入单板集成传感模块技术平台，在保证预警性能的同时，将体积和功耗均降为常规设备的1/10以下，可完全脱离机房环境进行户外灵活部署，更适用于野外重要线路或临时性重点线路预警等更多应用场景。
                 </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Separator/>

    <div class="rn-section-gap ">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title text-center" data-aos="fade-up">
              <h2 class="title w-600">监测原理</h2>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-12">
                <div class="section-title" data-aos="fade-up">
                  <h4 class="subtitle">系统工作原理</h4>
                </div>
              </div>
              <div class="col-5">
                传感光纤没有受到外界干扰情况下，激光信号正常传播路径，如下图：
              </div>
              <div class="col-5 offset-2">
                传感光纤感应到振动、移动等干扰时，激光信号发生偏移，如下图：
              </div>
              <div class="col-12 mb-5">
                <img :src="require('@/assets/images/fs/pi-2-1.png')" alt="">
              </div>
            </div>
            <div class="col-lg-12">
              <div class="section-title" data-aos="fade-up">
                <h4 class="subtitle">定位原理</h4>
              </div>
            </div>
            <div class="col-12">
              系统发出光脉冲在光纤中运行时，经过不同位置激发的散射光，再回到系统的时间差，通过精确计算，就能得到各振动点在光纤上的位置信息，这也就是基于光时域反射技术（OTDR），从而得到振动信号源的位置。本系统的高速数据采集模块采用了100MHz的采集速率，即对光缆线路沿线进行每米的密集采样探测，再结合高精度的传感算法，实际现场定位精度达10米内。
              <br/>
              系统在初次运行时，系统会计算当时线路的长度，如果在后期运行中因为外界破坏使得线路发生损坏断裂，系统探测到的线路长度会明显变短，此时就触发了线路断裂警报，最新线路长度末端问题，即为断纤事故发生的位置。
            </div>
          </div>
        </div>
      </div>
    </div>

    <Separator/>

    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title text-center" data-aos="fade-up">
              <h2 class="title w-600">技术优势</h2>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <div class="mt-5">
                  <p>
                    低功耗分布式线路振动预警系统，采用基于相位敏感光时域反射原理（Φ-OTDR）的分布式光纤振动传感技术，拥有分布式实时监测、高探测灵敏度和高定位精度（低至10m）的特点。本系统依托于arm架构嵌入式平台，在保证预警性能的同时，实现了极小的设备尺寸和极低的运行功耗，非常适合对功耗和设备大小有较高要求的线路预警应用场景。
                    极低功耗：突破光纤传感设备几十至上百瓦的功耗现状，实际运行功率仅9瓦。
                  </p>
                  <p>
                    极小尺寸重量，工控一体化集成：主机重量仅2.5kg，无需另配工控机；小尺寸可放入常规户外箱。
                    联网接口丰富，确保实时在线：主机通过有线网口，以及WIFI、4G上网卡等无线网络方式接入互联网或专网。
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 offset-lg-1 offset-md-1">
                <div class="mt-5">
                  <img :src="require(`@/assets/images/fs/product03.png`)" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Separator/>

    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title text-center" data-aos="fade-up">
              <h2 class="title w-600">技术参数</h2>
            </div>
          </div>
          <div class="col-lg-12 mt-5">
            <img :src="require(`@/assets/images/fs/pi-3-2.jpg`)" alt="">
          </div>
        </div>
      </div>
    </div>

    <Separator/>

    <div class="rn-section-gap">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title text-center" data-aos="fade-up">
              <h2 class="title w-600">产品应用</h2>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="row row--15 service-wrapper">
              <div class="col-lg-3 col-md-6 col-sm-6 col-12" v-for="application in applications">
                <div data-aos="fade-up" data-aos-delay="70" class="aos-init aos-animate">
                  <div class="service service__style--2 text-center">
                    <div class="inner">
                      <div class="image"><img :src="application.image" alt="Service Images"/></div>
                      <div class="content">
                        <h4 class="title"><a href="javascript:void(0)" class="">{{ application.title }}</a></h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Separator/>

  </div>
</template>

<script>
import Separator from "@/components/elements/separator/Separator";

export default {
  name: "PI3",
  components: {Separator},
  data () {
    return {
      applications: [
        {title: '管道防外破监测', image: require('@/assets/images/fs/pi-2-4-1.jpg')},
        {title: '公路护栏撞击监测', image: require('@/assets/images/fs/pi-2-4-2.jpg')},
        {title: '通讯光缆防外破监测', image: require('@/assets/images/fs/pi-2-4-3.jpg')},
        {title: '电缆防外破监测', image: require('@/assets/images/fs/pi-2-4-4.jpg')},
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.image {
  img {
    max-height: inherit !important;
  }
}
.rn-section-gap{
  padding: 60px 0 !important;
}
.contentPad{
  padding-top: 0 !important;
}
</style>
