export default class AppFunctions {

    static getOrDefault(name, value) {
        if (name === undefined || name === null) {
            return value
        }
        return name
    }

    static toggleStickyHeader() {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 100) {
            AppFunctions.addClass('.header-default', 'sticky');
        } else if (scrolled <= 100) {
            AppFunctions.removeClass('.header-default', 'sticky');
        }
    }

    static addScrollEventListener() {
        AppFunctions.addClass('body', 'active-light-mode')
        window.addEventListener('scroll', AppFunctions.toggleStickyHeader);
    }

    static removeScrollEventListener() {
        window.removeEventListener('scroll', AppFunctions.toggleStickyHeader);
    }

    static addClass(element, className) {
        let selector = document.querySelector(element);
        if (selector) {
            selector.classList.add(className)
        }
    }

    static removeClass(element, className) {
        let selector = document.querySelector(element);
        if (selector) {
            selector.classList.remove(className)
        }
    }

    static toggleClass(element, className) {
        let selector = document.querySelector(element);
        if (selector) {
            selector.classList.toggle(className)
        }
    }

    static containsClass(element, className) {
        let selector = document.querySelector(element);
        if (selector) {
            return selector.classList.contains(className)
        }
        return false
    }

    static flatDeep(arr, d = 1) {
        return d > 0 ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? this.flatDeep(val, d - 1) : val), []) : arr.slice();
    }

    static slugify(text) {
        return text
            .toString()
            .toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(/[^\w-]+/g, '') // Remove all non-word chars
            .replace(/--+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, '') // Trim - from end of text
    }

    static containsObject(obj, list) {
        let i;
        for (i = 0; i < list.length; i++) {
            if (list[i].slug === obj.slug) {
                return i;
            }
        }

        return -1;
    }
}
