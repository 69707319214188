<template>
  <Layout header-class="header-left-align" nav-alignment="left" :header-transparency="true" :show-top-header="false"
          :header-type="2" :footer-style="4">
    <div class="slider-area slider-style-1 variation-default height-950 bg_image proImgPosition"
         :style="{'background-image': `url(${require(`@/assets/images/fs/bannerProduct.png`)})`}">
      <div class="container mt-5 mb-5 proTabBar">
        <div class="row button-group">
          <div class="col-lg-3 col-md-4 col-sm-4 col-4" v-for="tab in tabs">
            <Button variant-button-class="text-underline" data-aos="fade-up" :size="getID() === tab.id?'':'small'"
                    :class="{'bgColor' :getID() === tab.id}"
                    :url="tab.url">
              <span class="tabTitle">{{ tab.title }}</span>
            </Button>
          </div>
        </div>
      </div>
    </div>

    <PI1 v-if="getID() === 1"/>
    <PI2 v-else-if="getID()===2"/>
    <PI3 v-else-if="getID()===3"/>
    <PI4 v-else-if="getID()===4"/>
  </Layout>
</template>

<script>

import Layout from "@/components/common/Layout";
import Separator from "@/components/elements/separator/Separator";
import Button from "@/components/elements/button/Button";
import PI1 from "@/views/fs/product-introduction/PI1";
import PI2 from "@/views/fs/product-introduction/PI2";
import PI3 from "@/views/fs/product-introduction/PI3";
import PI4 from "@/views/fs/product-introduction/PI4";

export default {
  name: "ProductIntroduction",
  components: {
    PI1,
    PI2,
    PI3,
    PI4,
    Layout, Separator, Button
  },
  data () {
    return {
      tabs: [
        {title: '手持式光传输线路智能运维仪(光缆路由定位仪)', url: '/product-introduction/1', id: 1},
        {title: '同步八通道振动智能监测设备', url: '/product-introduction/2', id: 2},
        {title: '小型低功耗光纤振动智能监测设备', url: '/product-introduction/3', id: 3},
        {title: '小型低功耗光纤温度智能监测设备', url: '/product-introduction/4', id: 4},
      ]
    }
  },
  methods: {
    getID () {
      return Number(this.$route.params.id)
    }
  },
}
</script>

<style scoped lang="scss">
.proImgPosition{
  position: relative;
  z-index: 9;
  .proTabBar{
    position: absolute;
    z-index: 12;
    top: 750px;
    left: 10px;
    a{
      height: 60px;
      background: #ffffff;
      border: none;
    }
    a:hover{
      background: #0064B0;
      color: #ffffff;
    }
    .bgColor{
      background: #0064B0 !important;
      color: #ffffff !important;
    }
  }
}
.tabTitle{
  font-size: 16px !important;
}
@media screen and (max-width: 1000px) and (min-width: 780px){
  .proImgPosition{
    z-index: 9;
    padding-bottom: 500px;
    .proTabBar{
      z-index: 12;
      top: 535px;
      left: 10px;
    }
  }
}
@media screen and (max-width: 1200px) and (min-width: 1000px){
  .proImgPosition{
    z-index: 9;
    .proTabBar{
      z-index: 12;
      top: 750px;
      left: 10px;
    }
  }
}
@media screen and (max-width: 779px){
  .proImgPosition{
    z-index: 9;
    padding-bottom: 240px;
    margin-bottom: 150px;
    .proTabBar{
      z-index: 12;
      top: 378px;
      left: 10px;
    }
    .tabTitle{
      font-size: 12px !important;
    }
    a{
      overflow: hidden;
    }
  }
}
</style>
