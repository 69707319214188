<template>
  <div class="index-box">
    <MapViewer v-if="showMap"></MapViewer>
    <Layout v-else header-class="header-left-align" nav-alignment="left" :header-transparency="true" :show-top-header="false"
            :header-type="2" :footer-style="4">
      <!-- Start Slider Area -->
      <div class="slider-area slider-style-1 variation-default"
           :style="{'background-image': `url(${require(`@/assets/images/fs/header-video-bg.jpg`)})`}">
        <div class="container-fluid p-0">
          <div class="row">
            <div class="col-12">
              <video playsinline muted autoplay loop class="bg-video"
                     :src="require('@/assets/images/fs/header-video.mp4')"></video>
            </div>
          </div>
        </div>
      </div>
      <!-- End Slider Area -->
      <separator/>

      <!-- Start AboutUs Area -->
      <div class="rwt-about-area rn-section-gap">
        <div class="container">
          <div class="row row--30 align-items-center">
            <div class="col-lg-5">
              <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
                <img class="w-100" src="../../assets/images/fs/about-fs.jpg" alt="About Images">
              </div>
            </div>
            <div class="col-lg-7 mt_md--40 mt_sm--40">
              <div class="content">
                <div class="section-title">
                  <h3 class="subtitle" data-aos="fade-up" data-aos-delay="280">
                    <span>无锡远知科技发展有限公司</span>
                  </h3>
                  <h3 class="title mt--10" data-aos="fade-up" data-aos-delay="310">关于远知</h3>
                  <p data-aos="fade-up" data-aos-delay="310">
                    无锡远知科技发展有限公司成立于2022年4月2日，是一家专注于智能化光纤传感产品研发、生产和销售的高科技企业。
                  </p>
                  <h3 class="title mt--10" data-aos="fade-up" data-aos-delay="310">技术团队</h3>
                  <p data-aos="fade-up" data-aos-delay="310">研发核心团队拥有超10年的光学传感技术研究及超8年的光纤传感市场应用和数据积累沉淀，在通信、电力、石化、交通和国防等5大领域拥有超过300+个实际场景应用经验。</p>
                  <h3 class="title mt--10" data-aos="fade-up" data-aos-delay="310">技术领头人 张承涛博士，十年磨一剑</h3>
                  <p data-aos="fade-up" data-aos-delay="310">
                    南京大学物理系国家基础人才班获理学学士学位，保送至中国科学院上海光学精密机械研究所硕博连读，师从于神光II大型高功率激光装置总师---中科院院士林尊琪。在中科院期间，参与多项国家863及上海市光科技重大专项研究项目，研究领域包括：特种光纤光栅器件、DFB单纵模光纤激光器、光纤放大器，及其在高功率激光装置和光纤传感系统的应用。在8年的企业实操阶段，带领团队研发成功“分布式光纤测温系统”、“分布式光纤振动传感系统”和“立体式周界安防监控系统”三大基础产品，各核心技术参数达到国内领先、国际一流水平.....
                  </p>
                  <div class="read-more-btn mt--40" data-aos="fade-up" data-aos-delay="370">
                    <Button size="small" variant-button-class="text-underline" data-aos="fade-up" url="/about-us">
                      <span>查看更多</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End AboutUs Area -->
      <Separator/>

      <!-- Start Service Area -->
      <section class="rn-service-area rn-section-gap">
        <div class="container">
          <div class="row row--30">
            <div class="col-lg-12">
              <SectionTitle
                  class="mb--50"
                  text-align="center"
                  subtitle=""
                  title="行业应用"
                  description=""
                  data-aos="fade-up"
                  data-aos-delay="60"
              />
            </div>
          </div>
          <FSService
              service-style="service__style--2"
              text-align="center"
          />
        </div>
      </section>

      <Separator/>
      <!-- Start Tab Area  -->
      <div class="rwt-advance-tab-area rn-section-gap">
        <div class="container">
          <div class="row mb--40">
            <div class="col-lg-12">
              <SectionTitle
                  textAlign="center"
                  subtitle=""
                  title="产品分类"
                  data-aos="fade-up"
              />
            </div>
          </div>
          <AdvancedTab :tab-data="tabData"/>
        </div>
      </div>
      <!-- End Tab Area  -->

    </Layout>

  </div>
</template>

<script>
import Layout from "@/components/common/Layout";
import Icon from "@/components/icon/Icon";
import Button from "@/components/elements/button/Button";
import Video from "@/components/elements/video/Video";
import SectionTitle from "@/components/elements/sectionTitle/SectionTitle";
import Tab from "@/components/elements/tab/FSTab";
import FSService from "@/components/elements/service/FSService";
import Split from "@/components/elements/split/Split";
import Separator from "@/components/elements/separator/Separator";
import AdvancedTab from "@/components/elements/tab/FSAdvancedTab";
import MapViewer from "@/views/fs/MapViewer";

export default {
  name: "Index",
  components: {
    Button, Separator, AdvancedTab, Split, Video, Layout, Icon, SectionTitle, FSService, Tab, MapViewer
  },
  mounted () {
    this.showMap = window.location.pathname === '/app/share/map'
  },
  data () {
    return {
      showMap:false,
      tabData: {
        tabContent: [
          {
            id: 1,
            menu: 'market-research',
            url: "/product-introduction/1",
            title: '手持式光传输线路智能运维仪(光缆路由定位仪)',
            image: require('@/assets/images/fs/product01.png'),
          },
          {
            id: 2,
            menu: 'corporate-report',
            url: "/product-introduction/2",
            title: '同步八通道振动智能监测设备',
            image: require('@/assets/images/fs/product2.png'),
          },
          {
            id: 3,
            menu: 'app-development',
            url: "/product-introduction/3",
            title: '小型低功耗光纤振动智能监测设备',
            image: require('@/assets/images/fs/product03.png'),
          },
          {
            id: 4,
            menu: 'device-dts',
            url: "/product-introduction/4",
            title: '小型低功耗光纤温度智能监测设备',
            image: require('@/assets/images/fs/product04.jpg'),
          }
        ]
      },
    }
  }
}
</script>

<style scoped>
.index-box {
  height: 100%;
}

.bg-video {
  height: auto;
  width: 100%;
}
</style>
