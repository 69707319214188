<script src="../../router/index.js"></script>
<template>
  <div class="hideBody">
    <div class="content">
      <h4>光传输线路智能运维仪-远程控制端隐私政策</h4>
      <p>光传输线路智能运维仪-远程控制端（Remote-controlled for IMIOT）尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，光传输线路智能运维仪-远程控制端会按照本隐私权政策的规定使用和披露您的个人信息，并将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，光传输线路智能运维仪-远程控制端不会将这些信息对外披露或向第三方提供。光传输线路智能运维仪-远程控制端会不时更新本隐私权政策。 您在同意光传输线路智能运维仪-远程控制端服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于光传输线路智能运维仪-远程控制端服务使用协议不可分割的一部分。</p>
      <ul>
        <li><h5>1．适用范围</h5></li>
        <li>a．在您安装和使用光传输线路智能运维仪-远程控制端app时，本软件要求您提供的个人手机应用权限及信息；</li>
        <li>b．在您使用光传输线路智能运维仪-远程控制端进行相关设备的远程连接及控制时，光传输线路智能运维仪-远程控制端中要求您输入的控制指令等信息；</li>
        <li>c. 在您使用光传输线路智能运维仪-远程控制端进行相关设备的远程连接及控制时,部分app功能要求您提供的个人手机的精确及精略定位信息。</li>
        <li><h5>您了解并同意，以下信息不适用本隐私权政策：</h5></li>
        <li>a．您在使用光传输线路智能运维仪-远程控制端app提供的数据输入和修改功能时输入的文字信息；</li>
        <li>b．光传输线路智能运维仪-远程控制端收集到的您在光传输线路智能运维仪-远程控制端发布的针对所连接硬件设备的控命令信息数据，包括但不限于对所控制设备参数、数据名称、运行状态的修改及设定等；</li>
        <li>c．违反法律规定或违反光传输线路智能运维仪-远程控制端规则行为。</li>
        <li><h5>2．信息使用</h5></li>
        <li>a．光传输线路智能运维仪-远程控制端不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和光传输线路智能运维仪-远程控制端（含光传输线路智能运维仪-远程控制端关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。</li>
        <li>b．光传输线路智能运维仪-远程控制端亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何光传输线路智能运维仪-远程控制端平台用户如从事上述活动，一经发现，光传输线路智能运维仪-远程控制端有权立即终止与该用户的服务协议。</li>
        <li>c．为服务用户的目的，光传输线路智能运维仪-远程控制端可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与光传输线路智能运维仪-远程控制端合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。</li>
        <li><h5>3．信息披露</h5></li>
        <li>在如下情况下，光传输线路智能运维仪-远程控制端将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：</li>
        <li>a．经您事先同意，向第三方披露；</li>
        <li>b．为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；</li>
        <li>c．根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；</li>
        <li>d．如您出现违反中国有关法律、法规或者光传输线路智能运维仪-远程控制端服务协议或相关规则的情况，需要向第三方披露；</li>
        <li>e．如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；</li>
        <li>g．其它光传输线路智能运维仪-远程控制端根据法律、法规或者相关政策认为合适的披露。</li>
        <li><h5>4．信息存储和交换</h5></li>
        <li>光传输线路智能运维仪-远程控制端不会收集的有关您的个人信息和资料。</li>
        <li><h5>5．Cookie的使用</h5></li>
        <li>光传输线路智能运维仪-远程控制端不会要求您提供cookies及相关资料信息。</li>
        <li>包括推广服务。</li>
        <li><h5>6．信息安全</h5></li>
        <li>a．光传输线路智能运维仪-远程控制端除了部分app功能需要单次使用用户个人手机中相机功能和相册中的对应图片，以及手机精确和粗略定位信息，不会收集或保存任何用户个人信息，因此不会造成您的信息的丢失、滥用等问题，在信息保护方面具有很高的安全性。</li>
        <li>b．在使用光传输线路智能运维仪-远程控制端不会进行任何网上交易，更不会因此造成任何您的个人泄密。</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppAboutHide"
}
</script>

<style scoped lang="scss">
.hideBody{
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  .content{
    background-color: #ffffff;
    width: 50%;
    height: 100%;
    margin: 0 auto;
    padding: 20px;
    h4{ text-align: center;}
    p{text-indent: 24px}
    ul{
      list-style: none;
      li{
        font-size: 14px;
        h5{margin: 20px 0}
      }
    }
  }
}
@media only screen and (max-width: 1080px) {
  .content{
    background-color: #ffffff;
    width: 100% !important;
    height: 100%;
    margin: 0 auto;
    padding: 20px;
    h4{font-size: 18px}
  }
}
</style>
