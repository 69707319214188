<template>
  <div class="ia1">
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 mb-5">
            <h3>监测领域</h3>
            <slot/>
          </div>
          <div class="col-lg-8 col-md-6 col-sm-12 col-12">
            <div class="row mb-5" data-aos="fade-up" data-aos-delay="50">
              <img :src="require(`@/assets/images/fs/ia-01-1.jpg`)" alt="通讯领域智能安全监测" title="通讯领域智能安全监测">
            </div>
            <div class="row" data-aos="fade-up" data-aos-delay="100">
              <div class="col-4">
                <h4 class="text-center">分缆识别</h4>
                <p class="small">
                  信息管线日趋复杂，井下和架空大量光纤资源缺乏标识登记；
                  故障抢修时面对大量光缆，无从下手；
                  重要线路光缆割接时，“咔嚓一刀”需要勇气和运气；
                  敲缆仪使用耗时多，需从几十根缆中逐一抽出测试。
                </p>
              </div>
              <div class="col-4">
                <h4 class="text-center">故障定位</h4>
                <p class="small">
                  城市建设频繁施工、自然灾害等造成光纤故障；
                  重要线路（通信干线、国防光缆等）仅允许极短故障时间，必须尽快抢通；
                  OTDR只能获取故障点皮长，难以快速找到非开放式（被掩盖）故障实际位置；
                  复杂环境中，很难实现故障目标缆的最终确认。
                </p>
              </div>
              <div class="col-4">
                <h4 class="text-center">路由标定</h4>
                <p class="small">
                  线路峻工资料不完整，线路走向定位不准确；
                  线路存在大量盘纤、熔接点和分纤点，光纤长度与线路地理长度不一致；
                  OTDR只能获取线路皮长，无法进行实际现场定位；
                  线路迁改、维修等，路由走向和光纤长度经常改变。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Separator/>
    <div class="rn-section-gap">
      <div class="container">
        <div class="row" data-aos="fade-up" data-aos-delay="100">
          <div class="col-lg-5 col-md-5">
            <h3>分缆识别监测</h3>
            <h5>监测系统: 双模式快速确认目标</h5>
            <p>
              快速寻缆模式：①不下井整体扰动确认线路、位置 ；
              ②二分法扰动快速缩小范围；
            </p>
            <p>
              切换精准模式：③精准识别模式确认目标缆，
              进行标识或割接操作。
            </p>
          </div>
          <div class="col-lg-7 col-md-7" data-aos="fade-up" data-aos-delay="150">
            <img :src="require('@/assets/images/fs/ia-01-2.jpg')" alt="分缆识别监测" title="分缆识别监测">
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-lg-5 col-md-5" data-aos="fade-left" data-aos-delay="200">
            <h5>监测平台显示</h5>
            <p>整体扰动/二分法高效测试；</p>
            <p>精准单点直观“心跳”界面，精确识别单纤；</p>
            <p>双模式实时同步曲线，判断精准。</p>
          </div>
          <div class="col-lg-7 col-md-7" data-aos="fade-right" data-aos-delay="250">
            <img :src="require('@/assets/images/fs/ia-01-3.jpg')" alt="">
          </div>
        </div>
      </div>
    </div>
    <Separator/>
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-5" data-aos="fade-up-left" data-aos-delay="200">
            <h3>光缆故障定位监测</h3>
            <h5>监测系统</h5>
            <p>
              OTDR事件中选择目标→扰动/敲击线路某处→与目标点距离/方向→下一测点…→找到故障/分纤点
            </p>
          </div>
          <div class="col-lg-7 col-md-7" data-aos="fade-up-right" data-aos-delay="250">
            <img :src="require('@/assets/images/fs/ia-01-4.jpg')" alt="">
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-lg-5 col-md-5" data-aos="fade-down-left" data-aos-delay="200">
            <h5>监测平台显示</h5>
            <p>快速追踪光缆接头盒 (损耗)、断纤点位置；</p>
            <p>准确定位光缆分纤点(损耗)位置；</p>
            <p>界面直接给出目标距离及方向；</p>
            <p>测量数据可本地USB导出，或通过远程控制端导出分享。</p>
          </div>
          <div class="col-lg-7 col-md-7" data-aos="fade-down-right" data-aos-delay="250">
            <img :src="require('@/assets/images/fs/ia-01-5.jpg')" alt="">
          </div>
        </div>
      </div>
    </div>
    <Separator/>
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-5" data-aos="flip-up" data-aos-delay="200">
            <h3>光缆路由标定</h3>
            <h5>监测系统</h5>
            <p>现场获取测点纤长与经纬度，实现光缆皮长与地理位置“点对点”对应;</p>
            <p>各测点振动获取纤长/坐标→内建管理系统生成、存储数据→本地/远程导出数据表→导航分享</p>
          </div>
          <div class="col-lg-7 col-md-7" data-aos="flip-down" data-aos-delay="250">
            <img :src="require('@/assets/images/fs/ia-01-6.jpg')" alt="">
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-lg-5 col-md-5" data-aos="flip-left" data-aos-delay="200">
            <h5>监测平台显示</h5>
            <p>不下井/排水/抽缆，打点式测量；</p>
            <p>远控端app获取地图数据；</p>
            <p>各测点随机测量，自然积累数据；</p>
            <p>高效清查暗资源；</p>
            <p>线路路由数据本地/远程导出。</p>
          </div>
          <div class="col-lg-7 col-md-7" data-aos="flip-right" data-aos-delay="250">
            <img :src="require('@/assets/images/fs/ia-01-7.jpg')" alt="">
          </div>
        </div>
      </div>
    </div>
    <Separator/>
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-5" data-aos="slide-up" data-aos-delay="200">
            <h3>光缆防外破监测</h3>
            <h5>监测系统</h5>
            <p>利用光缆中的冗余芯（一芯即可）；</p>
            <p>将光缆与防外破监测系统连接，实时探知监测段的振动信号；</p>
            <p>监测系统实时判别振动信号，如发现外破事件，触发报警（声光报警、短信报警或远程报警），并可联动视频等采集证据；</p>
            <p>告知相关运维人员现场处理</p>
          </div>
          <div class="col-lg-7 col-md-7" data-aos="slide-down" data-aos-delay="250">
            <img :src="require('@/assets/images/fs/ia-01-8.jpg')" alt="">
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-lg-5 col-md-5" data-aos="slide-left" data-aos-delay="200">
            <h5>监测平台显示</h5>
            <p>系统可同时监测8条不同方向的管线（普通振动设备只能监测1条管线），根据现场所提供的GIS地图，导入到软件中，直观的展示现场8条光纤走向。</p>
            <p>报警时，系统会自动弹出单个或多个不同线路的报警窗口。</p>
            <p>时间：最新报警时间</p>
            <p>备注：用于报警地理位置信息显示，可在编辑不同的防区时添加附近的建筑物或是桩号等等信息，便于现场查证。</p>
          </div>
          <div class="col-lg-7 col-md-7" data-aos="slide-right" data-aos-delay="250">
            <img :src="require('@/assets/images/fs/ia-02-3.jpg')" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from "@/components/elements/accordion/FSAccordion";
import Separator from "@/components/elements/separator/Separator";

export default {
  name: "IA1",
  components: {Separator, Accordion},
}
</script>
