<template>
  <div class="pi2">

    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title text-center" data-aos="fade-up">
              <h2 class="title w-600">产品简述</h2>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="row" style="align-items: center">
              <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <div class="mt-5">
                  <img :src="require(`@/assets/images/fs/product004.jpg`)" alt="">
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 offset-lg-1 offset-md-1">
                <div class="mt-5">
                  <p>
                    小型低功耗分布式光纤测温系统，基于拉曼散射光时域反射（R-OTDR）原理，拥有分布式实时温度监测、高空间定位精度（±0.5m）、高测温精度(±1℃)的特点。
                  </p>
                  <p>
                    本系统基于ARM架构的高度集成技术，设备体积和功耗降低到常规的几分之一，可脱离常规机房和机柜条件灵活部署，除常规工业温度监测，还可适用于小空间工业环境、户外环境等应用场景。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Separator/>

    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title text-center" data-aos="fade-up">
              <h2 class="title w-600">监测原理</h2>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="row">
              <div class="col-12">
                <p>
                  光纤测温的机理是依据后向拉曼(Raman)散射效应。激光脉冲与光纤分子相互作用,发生散射，散射有多种，如：瑞利(Rayleigh)散射、布里渊(Brillouin)散射和拉曼(Raman)散射等。其中拉曼散射是由于光纤分子的热振动，它会产生一个比光源波长长的光，称斯托克斯(Stokes) 光，和一个比光源波长短的光，称为反斯托克斯(Anti-Stokes)光。光纤受外部温度的调制使光纤中的反斯托克斯(Anti-Stokes)光强发生变化，Anti-Stokes与Stokes的比值提供了温度的绝对指示，利用这一原理可以实现对沿光纤温度场的分布式测量。                </p>
              </div>
              <div class="col-12 mb-5" style="text-align: center">
                <img :src="require('@/assets/images/fs/pi-4-2.jpg')" alt="">
              </div>
            </div>
            <div class="col-12">
              <p style="margin: 30px 0">
                分布式光纤传感设备只需接入光纤单端，信号收发同源。把光纤作为传感及通讯单元。
              </p>
            </div>
            <div class="col-12 mb-5" style="text-align: center">
              <img :src="require('@/assets/images/fs/pi-4-3.jpg')" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <Separator/>

    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title text-center" data-aos="fade-up">
              <h2 class="title w-600">技术优势</h2>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                <div class="mt-5">
                  <ul class="product_art">
                    <li><span>连续分布式温度监测：</span>实现全线路多点多事件同时监测、同时报警。</li>
                    <li><span>极小尺寸重量，工控一体化集成：</span>主机重量仅2.5kg，无需另配工控机，小尺寸安装方便。</li>
                    <li><span>联网接口丰富：</span>主机可通过RJ45接口或串口扩展无线上网接口方式，接入互联网或专网。</li>
                    <li><span>智能化组网平台：</span>通过平台服务器，可将多条测温线路的监测主机进行组网，统一进行监测目标温度状态、警报信息的显示和管理，发生报警时联动推送警报。</li>
                    <li><span>极低功耗：</span>突破光纤传感设备几十瓦功耗现状，高度集成，平均运行功率不超过5瓦，耗能小更安全。</li>
                    <li><span>真实分布定位：</span>定位精度±1米，各警报分区参数可灵活设定，适应各类监测场景。</li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 offset-lg-1 offset-md-1">
                <div class="mt-5">
                  <img :src="require(`@/assets/images/fs/pi-4-4.jpg`)" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Separator/>

    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title text-center" data-aos="fade-up">
              <h2 class="title w-600">技术参数</h2>
            </div>
          </div>
          <div class="col-lg-12 mt-5">
            <img :src="require(`@/assets/images/fs/pi-4-5.jpg`)" alt="">
          </div>
        </div>
      </div>
    </div>

    <Separator/>

    <div class="rn-section-gap">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title text-center" data-aos="fade-up">
              <h2 class="title w-600">产品应用</h2>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="row row--15 service-wrapper">
              <div class="col-lg-3 col-md-6 col-sm-6 col-12" v-for="application in applications">
                <div data-aos="fade-up" data-aos-delay="70" class="aos-init aos-animate">
                  <div class="service service__style--2 text-center">
                    <div class="inner">
                      <div class="image"><img :src="application.image" alt="Service Images"/></div>
                      <div class="content">
                        <h4 class="title"><a href="javascript:void(0)" class="">{{ application.title }}</a></h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Separator/>

  </div>
</template>

<script>
import Separator from "@/components/elements/separator/Separator";

export default {
  name: "PI3",
  components: {Separator},
  data() {
    return {
      applications: [
        {title: '交通隧道温度监测', image: require('@/assets/images/fs/pi-4-6.jpg')},
        {title: '电缆本体/通道温度监测', image: require('@/assets/images/fs/pi-4-7.jpg')},
        {title: 'UPS电池仓库温度监测', image: require('@/assets/images/fs/pi-4-8.jpg')},
        {title: '储油罐温度监测', image: require('@/assets/images/fs/pi-4-9.jpg')},
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.image {
  img {
    max-height: inherit !important;
  }
}

.rn-section-gap {
  padding: 60px 0 !important;
}

.contentPad {
  padding-top: 0 !important;
}
.product_art{
  list-style: none;
  li{font-size: 16px;padding: 5px 0}
  span{color: black;font-weight: 800}
}
</style>
