<template>
  <div class="ia5">
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 mb-5">
            <h3>监测领域</h3>
            <slot/>
          </div>
          <div class="col-lg-8 col-md-6 col-sm-12 col-12">
            <div class="row mb-5">
              <img :src="require(`@/assets/images/fs/ia-05-1.jpg`)" alt="国防领域智能安全监测" title="国防领域智能安全监测">
            </div>
            <div class="row">
              <h3 class="text-center">行业前景</h3>
              <p>
                <span class="w-600">国防光缆行业前景:</span>
                <span class="small">国防光缆和普通通信光缆不一样，国防光缆一般邻着于国道、公路，往往布设距离较长，且常处于城市或野外复杂的自然环境中，地质活动及人为的施工开挖等都可能造成光缆损坏，光纤焊接头老化、地震、地质沉降等自然现象则有可能造成的光缆断点。而光缆损坏及断点位置查找比较困难，尤其是一些隐蔽性断点，更难被发现。往往盲目的去找会耗费不必要的财力和人力，有时甚至会花费几天的时间也无法准确找出准确的位置，给高速公路、国防等的通讯以及生活、生产带来极大不便。</span>
              </p>
              <p>
                <span class="w-600">军队周界行业前景:</span>
                <span class="small">军队守护着国家和人民的安全，部分军队驻守在祖国的边境，国防边境环境较为复杂，对于偷渡与越界行为无法全面监控；且国家军用机场、军队弹药库、打靶场、军用油库等高等机密场所更是肩负着部队作战安全与作战能源补充，所以对部队整体周界安防的监测尤为重要。</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Separator/>
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-5">
            <h3>分缆识别监测</h3>
            <h5>监测系统: 双模式快速确认目标</h5>
            <p>
              快速寻缆模式：①不下井整体扰动确认线路、位置 ；
              ②二分法扰动快速缩小范围；
            </p>
            <p>
              切换精准模式：③精准识别模式确认目标缆，
              进行标识或割接操作。
            </p>
          </div>
          <div class="col-lg-7 col-md-7">
            <img :src="require('@/assets/images/fs/ia-01-2.jpg')" alt="分缆识别监测" title="分缆识别监测">
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-lg-5 col-md-5">
            <h5>监测平台显示</h5>
            <p>整体扰动/二分法高效测试；</p>
            <p>精准单点直观“心跳”界面，精确识别单纤；</p>
            <p>双模式实时同步曲线，判断精准。</p>
          </div>
          <div class="col-lg-7 col-md-7">
            <img :src="require('@/assets/images/fs/ia-01-3.jpg')" alt="监测平台显示" title="监测平台显示">
          </div>
        </div>
      </div>
    </div>
    <Separator/>
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-5">
            <h3>光缆故障定位监测</h3>
            <h5>监测系统</h5>
            <p>
              OTDR事件中选择目标→扰动/敲击线路某处→与目标点距离/方向→下一测点…→找到故障/分纤点
            </p>
          </div>
          <div class="col-lg-7 col-md-7">
            <img :src="require('@/assets/images/fs/ia-01-4.jpg')" alt="">
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-lg-5 col-md-5">
            <h5>监测平台显示</h5>
            <p>快速追踪光缆接头盒 (损耗)、断纤点位置；</p>
            <p>准确定位光缆分纤点(损耗)位置；</p>
            <p>界面直接给出目标距离及方向；</p>
            <p>测量数据可本地USB导出，或通过远程控制端导出分享。</p>
          </div>
          <div class="col-lg-7 col-md-7">
            <img :src="require('@/assets/images/fs/ia-01-5.jpg')" alt="">
          </div>
        </div>
      </div>
    </div>
    <Separator/>
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-5">
            <h3>光缆路由标定</h3>
            <h5>监测系统</h5>
            <p>现场获取测点纤长与经纬度，实现光缆皮长与地理位置“点对点”对应;</p>
            <p>各测点振动获取纤长/坐标→内建管理系统生成、存储数据→本地/远程导出数据表→导航分享</p>
          </div>
          <div class="col-lg-7 col-md-7">
            <img :src="require('@/assets/images/fs/ia-01-6.jpg')" alt="">
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-lg-5 col-md-5">
            <h5>监测平台显示</h5>
            <p>不下井/排水/抽缆，打点式测量；</p>
            <p>远控端app获取地图数据；</p>
            <p>各测点随机测量，自然积累数据；</p>
            <p>高效清查暗资源；</p>
            <p>线路路由数据本地/远程导出。</p>
          </div>
          <div class="col-lg-7 col-md-7">
            <img :src="require('@/assets/images/fs/ia-01-7.jpg')" alt="">
          </div>
        </div>
      </div>
    </div>
    <Separator/>
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-5">
            <h3>光缆防外破监测</h3>
            <h5>监测系统</h5>
            <p>利用光缆中的冗余芯（一芯即可）；</p>
            <p>将光缆与防外破监测系统连接，实时探知监测段的振动信号；</p>
            <p>监测系统实时判别振动信号，如发现外破事件，触发报警（声光报警、短信报警或远程报警），并可联动视频等采集证据；</p>
            <p>告知相关运维人员现场处理</p>
          </div>
          <div class="col-lg-7 col-md-7">
            <img :src="require('@/assets/images/fs/ia-01-8.jpg')" alt="">
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-lg-5 col-md-5">
            <h5>监测平台显示</h5>
            <p>系统可同时监测8条不同方向的管线（普通振动设备只能监测1条管线），根据现场所提供的GIS地图，导入到软件中，直观的展示现场8条光纤走向。</p>
            <p>报警时，系统会自动弹出单个或多个不同线路的报警窗口。</p>
            <p>时间：最新报警时间</p>
            <p>备注：用于报警地理位置信息显示，可在编辑不同的防区时添加附近的建筑物或是桩号等等信息，便于现场查证。</p>
          </div>
          <div class="col-lg-7 col-md-7">
            <img :src="require('@/assets/images/fs/ia-02-3.jpg')" alt="">
          </div>
        </div>
      </div>
    </div>
    <Separator/>
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-5">
            <h3>军营周界禁区防护</h3>
            <h5>监测系统</h5>
            <p>
              为帐篷周界加装智能光纤禁区防护监测系统，并联动视频监控系统。
              出现人员在上方踩踏时，准确探测定位信号发生位置并发出警报。
              确保对出现在禁行区域的所有人员行动实时监控掌握。
            </p>
            <h5>监测平台显示</h5>
            <p class="p-pro-mr-0">根据现场所提供的GIS地图，导入到软件中，直观的展示现场光纤走向。</p>
            <p  class="p-pro-mr-0">报警时，会自动弹出一个报警窗口。</p>
            <p class="p-pro-mr-0">监测区域实景地图显示、防区报警事件处理</p>
            <p class="p-pro-mr-0">入侵预警功能显示、精确定位报警位置</p>
            <p class="p-pro-mr-0">报警时间与等级显示</p>
            <p class="p-pro-mr-0">故障诊断报警、排除干扰信号</p>
          </div>
          <div class="col-lg-7 col-md-7">
            <img :src="require('@/assets/images/fs/ia-05-2.jpg')" alt="">
          </div>
        </div>

      </div>
    </div>
    <Separator/>
  </div>
</template>

<script>
import Accordion from "@/components/elements/accordion/FSAccordion";
import Separator from "@/components/elements/separator/Separator";

export default {
  name: "IA5",
  components: {Separator, Accordion},
}
</script>
<style>
.p-pro-mr-0{
  margin:0
}
</style>
