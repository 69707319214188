<template>
  <div class="pi1">
    <div class="rn-section-gap">
      <div class="container">
        <div class="row mb-5">
          <div class="col-lg-12">
            <div class="section-title text-center" data-aos="fade-up">
              <h2 class="title w-600">产品简介</h2>
            </div>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-lg-6 col-sm-12 col-12 mb-5">
            <img :src="require('@/assets/images/fs/pi-left.jpg')" alt="">
          </div>
          <div class="col-lg-5 col-sm-12 col-12 offset-lg-1 mb-5">
            <div class="row">
              <h4 class="mt-5">便携式</h4>
              <h5 class="mt-5">光传输线路智能运维仪(光缆路由定位仪)</h5>
              <p class="mt-5">
                该设备基于分布式光纤振动传感技术和光时域反射技术，将高灵敏度的光缆定位探测功能与OTDR光纤链路状态分析功能相结合，兼俱强大性能与轻便小巧、完善功能与智能易用，是光通信线路的新一代智能化运维终端。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Separator/>
    <div class="rn-section-gap technical-advantages">
      <div class="container">
        <div class="row mb-5">
          <div class="col-lg-12">
            <div class="section-title text-center" data-aos="fade-up">
              <h2 class="title w-600">技术优势</h2>
            </div>
          </div>
        </div>
        <div class="row ">
          <div class="col-lg-6 col-sm-12 col-12">
            <p>
              <span class="h6">集成便携：</span>
              <span class="small">OTDR/DVS双模集成，单光口时分复用； 重量不足2.5kg，外型符合人体工学，手持方便。</span>
            </p>
            <p>
              <span class="h6">功能丰富：</span>
              <span class="small">包括OTDR、故障定位、路由标定、数据管理等功能模块。</span>
            </p>
            <p>
              <span class="h6">大范围分布式传感：</span>
              <span
                  class="small">OTDR测量距离120km，振动传感距离达40km；全线精确定位，空间分辨1m，定位精度最佳至2m。</span>
            </p>
            <p>
              <span class="h6">8英寸高亮触屏：</span>
              <span class="small">分辨率1280*800，峰值亮度超过700nit；全手指精准操作，无需触控笔。</span>
            </p>
            <p>
              <span class="h6">互联网智能化终端：</span>
              <span
                  class="small">通过有线网口及WIFI、4G流量卡等无线网络方式接入互联网或专网；可本地或远程导出测试数据。</span>
            </p>
            <p>
              <span class="h6">远程控制端APP：</span>
              <span
                  class="small">远程“镜像投屏”技术实时操控，不限制场景和设备；机房连接线路后，1人1机即可完成所有测试，自动生成线路地图。</span>
            </p>
            <p>
              <span class="h6">长续航：</span>
              <span class="small">连续探测时间>8h，2天续航；大容量锂电池，45W PD快充。</span>
            </p>
          </div>
          <div class="col-lg-6 col-sm-12 col-12">
            <img :src="require('@/assets/images/fs/pi-1-2.jpg')" alt="">
          </div>
        </div>
      </div>
    </div>
    <Separator/>
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title text-center" data-aos="fade-up">
              <h2 class="title w-600">产品应用</h2>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="row row--15 service-wrapper">
              <div class="col-lg-4 col-md-4 col-sm-12 col-12" v-for="application in applications">
                <div data-aos="fade-up" data-aos-delay="70" class="aos-init aos-animate">
                  <div class="service service__style--2 text-center">
                    <div class="inner">
                      <div class="image"><img :src="application.image" alt="Service Images"/></div>
                      <div class="content">
                        <h4 class="title"><a href="javascript:void(0)" class="">{{ application.title }}</a></h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Separator/>
    <div class="rn-section-gap">
      <div class="container">
        <div class="row mb-5">
          <div class="col-lg-12">
            <div class="section-title text-center" data-aos="fade-up">
              <h2 class="title w-600">光传输线路智能运维仪(光缆路由定位仪)技术参数</h2>
            </div>
          </div>
        </div>
        <div class="row row--15">
          <img :src="require('@/assets/images/fs/pi-1-4.jpg')" alt="技术参数">
        </div>
      </div>
    </div>
    <Separator/>
    <div class="rn-section-gap">
      <div class="container">
        <div class="row mb-5">
          <div class="col-lg-12">
            <div class="section-title text-center" data-aos="fade-up">
              <h2 class="title w-600">产品展示</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <img :src="require('@/assets/images/fs/pi-1-5.jpg')" alt="产品展示">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Separator from "@/components/elements/separator/Separator";

export default {
  name: "PI1",
  components: {Separator},
  data () {
    return {
      applications: [
        {title: '分缆识别', image: require('@/assets/images/fs/pi-1-3-1.jpg')},
        {title: '故障定位', image: require('@/assets/images/fs/pi-1-3-2.jpg')},
        {title: '路由标定', image: require('@/assets/images/fs/pi-1-3-3.jpg')},
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.pi1{
  .technical-advantages{
    p{
      margin-bottom: 15px;
    }
  }
}
.image {
  img {
    max-height: 480px !important;
  }
}
.rn-section-gap{
  padding: 60px 0 !important;
}
.contentPad{
  padding-top: 0 !important;
}
</style>
