<template>
  <ul class="mainmenu">
    <template v-for="m in menu">
      <li :class="m.sub&&m.sub.length>0?`has-droupdown has-menu-child-item`:''">
        <router-link :to="m.to" :class="isActive(m.active)">
          <span>{{ m.title }}</span>
        </router-link>
        <ul class="submenu" v-if="m.sub&&m.sub.length>0">
          <li v-for="s in m.sub">
            <router-link :to="s.to" :class="isSubActive(s.to)">{{ s.title }}</router-link>
          </li>
        </ul>
      </li>
    </template>
  </ul>
</template>

<script>
import Icon from "@/components/icon/Icon";

export default {
  name: 'Nav',
  components: {Icon},
  data () {
    return {
      menu: [
        {title: '首页', active: 'index', to: '/', sub: []},
        {
          title: '产品介绍', active: 'product-introduction', to: '#', sub: [
            {title: '手持式光传输线路智能运维仪(光缆路由定位仪)', to: '/product-introduction/1'},
            {title: '同步八通道振动智能监测设备', to: '/product-introduction/2'},
            {title: '小型低功耗光纤振动智能监测设备', to: '/product-introduction/3'},
            {title: '小型低功耗光纤温度智能监测设备', to: '/product-introduction/4'}
          ]
        },
        {
          title: '行业应用', active: 'industry-application', to: '#', sub: [
            {title: '通讯领域智能监测', to: '/industry-application/1'},
            {title: '石化领域智能监测', to: '/industry-application/2'},
            {title: '电力领域智能监测', to: '/industry-application/3'},
            {title: '交通领域智能监测', to: '/industry-application/4'},
            {title: '国防领域智能监测', to: '/industry-application/5'}
          ]
        },
        {title: '成功案例', active: 'success-cases', to: '#', sub: [
            {title: '通讯领域案例展示', to: '/success-cases/1?title=communication1'},
            {title: '石化领域案例展示', to: '/success-cases/1?title=petrifaction1'},
            {title: '电力领域案例展示', to: '/success-cases/1?title=power1'},
            {title: '交通领域案例展示', to: '/success-cases/1?title=communication1'},
            {title: '国防领域案例展示', to: '/success-cases/1?title=national1'}
          ]},
        {title: '关于远知', active: 'about-us', to: '/about-us', sub: []},
        {title: '资源下载', active: 'resource-download', to: '/resource-download', sub: []},
      ],
    }
  },
  methods: {
    isActive (route) {
      return route === this.$route.name ? 'active' : ''
    },
    isSubActive (route) {
      return route === this.$route.path ? 'active' : ''
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
