<template>
  <main class="page-wrapper">
    <FSHeader v-if="headerType === 2" :data="$props"/>
    <div class="main-content">
      <slot></slot>
    </div>
    <FSFooter v-if="footerStyle === 4" :data="$props"/>
  </main>
</template>

<script>
import FSHeader from '@/components/common/header/fs/Header'
import FSFooter from "@/components/common/footer/FSFooter";

export default {
  name: 'Layout',
  components: {FSHeader, FSFooter},
  props: {
    logo: {
      type: String
    },
    showTopHeader: {
      type: Boolean,
      default: true
    },
    topbarStyle: {
      type: Number,
      default: 1
    },
    showThemeSwitcherButton: {
      type: Boolean,
      default: true
    },
    headerTransparency: {
      type: Boolean,
      default: false
    },
    headerType: {
      type: Number,
      default: 1
    },
    headerClass: {
      type: String
    },
    navAlignment: {
      type: String,
      default: 'right'
    },
    buyButton: {
      type: Boolean,
      default: false
    },
    buyButtonClass: {
      type: String,
      default: 'btn-default btn-small round'
    },
    footerStyle: {
      type: Number,
      default: 1
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    showCopyright: {
      type: Boolean,
      default: true
    },
    showNewsletter: {
      type: Boolean,
      default: false
    },
    showCta: {
      type: Boolean,
      default: true
    }
  }
}
</script>
