<template>
  <Layout header-class="header-left-align" nav-alignment="left" :header-transparency="true" :show-top-header="false"
          :header-type="2" :footer-style="4">
    <div class="slider-area slider-style-1 variation-default height-850 bg_image"
         :style="{'background-image': `url(${require(`@/assets/images/fs/about-us-banner.jpg`)})`}">
    </div>
    <Separator/>
    <div class="rn-section-gap aboutUs-content-top">
      <div class="container">
        <div class="row">
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-5 offset-xxl-1 offset-xl-1 offset-sm-0">
            <div class="h3">公司简介</div>
            <p class="mt-5">
              无锡远知科技发展有限公司成立于2022年4月2日，是一家专注于智能化光纤传感产品研发、生产和销售的高科技企业。研发核心团队拥有超10年的光学传感技术研究及超8年的光纤传感市场应用和数据积累沉淀，在通信、电力、石化、交通和国防等5大领域拥有超过300+个实际场景应用经验。远知以领先的技术为基础，以客户群的需求为导向，突破了业内唯一“基于嵌入式的单板集成化光电传感模块技术”、“基于人工智能算法的互联网化操控系统”，通过小型化、低功耗、智能化、低成本的技术路线，实现满足多场景、大规模、标准化应用的全新技术平台。核心产品：光传输线路智能运维仪、低功耗小型分布式光纤振动系统、同步多通道分布式光纤振动系统等均为业内独创，且都已得到实际用户的验证肯定。
            </p>
          </div>
          <div
              class="col-xxl-5 col-xl-5 col-lg-6 col-md-7 offset-xxl-1 offset-xl-1 offset-lg-2 offset-sm-0">
            <img :src="require('@/assets/images/fs/about-us-introction.jpg')" alt="about-us-introction">
          </div>
        </div>
      </div>
      <div class="aboutUsMr">
        <Separator/>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-4  offset-xxl-1 offset-xl-1 offset-sm-0 mt-5">
            <div>
              <img :src="require('@/assets/images/fs/about-us-leader.jpg')" alt="about-us-team-right.jpg">
            </div>
            <div class="text-center mt-2 w-600"></div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 offset-xl-1 offset-lg-1 offset-md-1 offset-sm-0">
            <div class=" mt-5">
              <span class="h3">研发领头人 张承涛博士</span>
              <p class="mt-5">
                南京大学物理系国家基础人才班获理学学士学位，保送至中国科学院上海光学精密机械研究所硕博连读，师从于神光II大型高功率激光装置总师---中科院院士林尊琪。在中科院期间，参与多项国家863及上海市光科技重大专项研究项目，研究领域包括：特种光纤光栅器件、DFB单纵模光纤激光器、光纤放大器，及其在高功率激光装置和光纤传感系统的应用。在8年的企业实操阶段，带领团队研发成功“分布式光纤测温系统”、“分布式光纤振动传感系统”和“立体式周界安防监控系统”三大基础产品，各核心技术参数达到国内领先、国际一流水平，并在电力系统、石化管道系统、通信维保、交通安全、运营商体系、周界安防等领域应用成为完整解决方案。其后，团队又全新研发了“公路边坡落石监控系统”、“新能源仓储智能消防定位系统及平台”“荧光光纤点式温度传感器及应用平台”、“隧道交通车辆状态监控系统及平台”等行业领先的解决方案。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="aboutUs-content-top-img">
        <img :src="require('@/assets/images/fs/about-us-leader-bg.jpg')" alt="about-us-leader-bg.jpg">
      </div>
    </div>

<!--    <Separator/>  横线-->
    <div class="rn-section-gap rn-section-gaps">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="row aboutUsIntroduce">
              <div
                  class="col-xxl-2 col-xl-2 col-lg-3 col-md-4 offset-lg-0 offset-md-0 offset-sm-0">
                  <!-- offset-xxl-1 offset-xl-1-->
                <div class="h3">技术团队</div>
              </div>
              <div class="col-xxl-7 col-xl-10 col-lg-9 col-md-8 offset-xxl-1 offset-xl-1 offset-lg-0 offset-sm-0">
                <p>
                  100%自主研发。公司的研发团队由具备光学工程、电子技术、计算机软件等多专业背景的人才组成，研发核心团队拥有超10年的光学传感技术研究及超8年的光纤传感市场应用和数据积累沉淀，在通信、电力、石化、交通和国防等5大领域拥有超过300+个实际场景应用经验。
                </p>
              </div>
            </div>
<!--            技术团队-->
            <div class="row">
              <img :src="require('@/assets/images/fs/about-us-jishu.jpg')" alt="about-us-jishu.jpg">
              <!--              <div class="col-xl-5 col-lg-6 col-md-6">-->
              <!--                <img :src="require('@/assets/images/fs/about-us-team-right.jpg')" alt="about-us-team-right.jpg">-->
              <!--              </div>-->
            </div>
            <div class="row aboutUsIntroduce aboutUsIntroduces">
              <div
                  class="col-xxl-2 col-xl-2 col-lg-3 col-md-4 offset-lg-0 offset-md-0 offset-sm-0">
                <div class="h3">100%自产</div>
              </div>
              <div class="col-xxl-7 col-xl-10 col-lg-9 col-md-8 offset-xxl-1 offset-xl-1 offset-lg-0 offset-sm-0">
                <p>
                  独立装配/测试车间。无锡远知科技发展有限公司拥有标准化的光路模块生产线、整机装配及测试车间，配备了各式光学、电学、机械结构、环境测试仪器设备。专业的技术团队在规范流程的指导下，确保出货产品性能、品质的一致、可靠。
               </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row aboutUsTeamImg">
        <img :src="require('@/assets/images/fs/about-us-team-left-new.jpg')" alt="about-us-team-left.jpg">
        <!--              <div class="col-xl-5 col-lg-6 col-md-6">-->
        <!--                <img :src="require('@/assets/images/fs/about-us-team-right.jpg')" alt="about-us-team-right.jpg">-->
        <!--              </div>-->
      </div>
    </div>
<!--    <Separator/>-->
<!--    <div class="rn-section-gap">-->
<!--      <div class="container">-->
<!--        <div class="row">-->
<!--          <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-4  offset-xxl-1 offset-xl-1 offset-sm-0 mt-5">-->
<!--            <div>-->
<!--              <img :src="require('@/assets/images/fs/about-us-leader.jpg')" alt="about-us-team-right.jpg">-->
<!--            </div>-->
<!--            <div class="text-center mt-2 w-600"></div>-->
<!--          </div>-->
<!--          <div class="col-xl-6 col-lg-6 col-md-6 offset-xl-1 offset-lg-1 offset-md-1 offset-sm-0">-->
<!--            <div class=" mt-5">-->
<!--              <span class="h3">研发领头人 张承涛博士</span>-->
<!--              <p>-->
<!--                南京大学物理系国家基础人才班获理学学士学位，保送至中国科学院上海光学精密机械研究所硕博连读，师从于神光II大型高功率激光装置总师-&#45;&#45;中科院院士林尊琪。在中科院期间，参与多项国家863及上海市光科技重大专项研究项目，研究领域包括：特种光纤光栅器件、DFB单纵模光纤激光器、光纤放大器，及其在高功率激光装置和光纤传感系统的应用。-->
<!--              </p>-->
<!--              <p>-->
<!--                在8年的企业实操阶段，带领团队研发成功“分布式光纤测温系统”、“分布式光纤振动传感系统”和“立体式周界安防监控系统”三大基础产品，各核心技术参数达到国内领先、国际一流水平，并在电力系统、石化管道系统、通信维保、交通安全、运营商体系、周界安防等领域应用成为完整解决方案。其后，团队又全新研发了“公路边坡落石监控系统”、“新能源仓储智能消防定位系统及平台”“荧光光纤点式温度传感器及应用平台”、“隧道交通车辆状态监控系统及平台”等行业领先的解决方案。-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <!--    <Separator/>  横线-->
  </Layout>
</template>

<script>
import Layout from "@/components/common/Layout";
import Separator from "@/components/elements/separator/Separator";
import Split from "@/components/elements/split/Split";

export default {
  name: "AboutUs",
  components: {Split, Separator, Layout}
}
</script>


<style scoped lang="scss">
.aboutUs-content-top{
  position: relative;
  .aboutUs-content-top-img{
    img{
      height: 180px;
      width: 100%;
    }
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: -1;
  }
}
.aboutUsIntroduce{
  align-items: center;
}
.aboutUsIntroduces{
  p{
    margin: 0;
  }
  margin: 70px 0 40px 0;
}
.aboutUsTeamImg{
  margin:0 80px;
}
@media (max-width:1400px) {
  .aboutUs-content-top-img{
    display: none;
  }
}
.rn-section-gaps{
  padding: 40px 0 !important;
}
.aboutUsMr{
  margin: 40px 0;
}
</style>
