<template>
  <div class="ia2">
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 mb-5">
            <h3>监测领域</h3>
            <slot/>
          </div>
          <div class="col-lg-8 col-md-6 col-sm-12 col-12">
            <div class="row mb-5" data-aos="zoom-in" data-aos-delay="100">
              <img :src="require(`@/assets/images/fs/ia-02-1.jpg`)" alt="通讯领域智能安全监测" title="通讯领域智能安全监测">
            </div>
            <div class="row" data-aos="zoom-in-up" data-aos-delay="200">
                <h3 class="text-center">行业前景</h3>
                <p class="description">
                  石油和天然气输运与国民经济和人民生活息息相关，管道输送相比道路运输具有安全、便捷、经济的特点，已成为关系能源产业发展的重要环节。长途油气管道具有高温、高压、易燃、易爆的特点，一旦发生泄漏将引发重大安全事故并造成巨大经济损失。近年来，管道泄漏爆炸、偷油盗油、野蛮施工等破坏行为严重威胁到管道的安全。管道由于外部扰动、腐蚀、管材和施工质量等原因发生失效事故，威胁国家经济和人民生命财产安全。
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Separator/>
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-5" data-aos="zoom-in-up" data-aos-delay="200">
            <h3>管道防外破监测</h3>
            <h5>监测系统</h5>
            <p>
              分别利用分布式光纤振动系统对管道进行全天候24小时实时在线监测，全面的掌握管道上方的振动信号变化，一旦出现开挖或破坏振动信号，系统可立即产生报警，同时精准定位事故位置。
            </p>
          </div>
          <div class="col-lg-7 col-md-7" data-aos="zoom-in-down" data-aos-delay="250">
            <img :src="require('@/assets/images/fs/ia-02-2.jpg')" alt="">
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-lg-5 col-md-5" data-aos="zoom-in-left" data-aos-delay="200">
            <h5>监测平台显示</h5>
            <p>系统可同时监测8条不同方向的管线（普通振动设备只能监测1条管线），根据现场所提供的GIS地图，导入到软件中，直观的展示现场8条光纤走向。</p>
            <p>报警时，系统会自动弹出单个或多个不同线路的报警窗口。</p>
            <p>时间：最新报警时间</p>
            <p>备注：用于报警地理位置信息显示，可在编辑不同的防区时添加附近的建筑物或是桩号等等信息，便于现场查证。</p>
          </div>
          <div class="col-lg-7 col-md-7" data-aos="zoom-in-right" data-aos-delay="250">
            <img :src="require('@/assets/images/fs/ia-02-3.jpg')" alt="">
          </div>
        </div>
      </div>
    </div>
    <Separator/>
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-5" data-aos="fade" data-aos-delay="200">
            <h3>清管器定位监测</h3>
            <h5>监测系统</h5>
            <p>
              利用分布式光纤振动监测系统对管道里的清管器进行实时在线监测，全面掌握管道内的堵塞情况，当管道内出现堵塞时，清管器则无法前进，监测光缆振动信号亦同步停止，此时系统可精准定位堵塞点位置，从而对管道堵塞位置进行定点修理。
            </p>
            <h5>监测平台显示</h5>
            <p>通过主机信号特征分析软件，清管器的移动运行轨迹可在瀑布图上实时显示，当清管器遇到堵塞停止时，可对应查看清管器在瀑布图上停止运行时的地理位置，从而可实现清管器的跟踪定位。</p>
          </div>
          <div class="col-lg-7 col-md-7" data-aos="zoom-in-right" data-aos-delay="250">
            <img :src="require('@/assets/images/fs/ia-02-4.jpg')" alt="">
          </div>
        </div>
<!--        <hr>-->
<!--        <div class="row">-->
<!--          <div class="col-lg-5 col-md-5" data-aos="zoom-in" data-aos-delay="200">-->
<!--           </div>-->
<!--          <div class="col-lg-7 col-md-7" data-aos="zoom-out" data-aos-delay="250">-->
<!--            <img :src="require('@/assets/images/fs/ia-02-5.jpg')" alt="">-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
    <Separator/>
    <div class="rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-5" data-aos="zoom-out-up" data-aos-delay="200">
            <h3>管道泄漏/露管温度监测</h3>
            <h5>监测系统</h5>
            <p class="small p-pro-mr-0">利用分布式光纤测温监测系统对管道进行全天候24小时实时在线监测，全面掌握管道的温度信号变化，当管道出现老化泄漏、施工破坏泄漏、管道裸露、阀门泄漏等异常温度变化时，系统可迅速发出警报，精准定位管道泄漏点或裸露点位置。同时系统还	具有联动声光报警、短信报警等功能，并还可以通过电脑远程监控查看现场报警状态。</p>
            <p class="p-pro-mr-0">监测光缆铺设：</p>
            <ul style="list-style: none">
              <li class="small">管道架空时光缆紧贴管道铺设；</li>
              <li class="small">管道入地时光缆埋于管道上方；</li>
              <li class="small">阀门处光缆环绕铺设。</li>
            </ul>
            <h5>监测平台显示</h5>
            <ul style="list-style: none;padding: 0">
              <li class="small">温度实时显示：系统可以实时显示各通道、各分区温度变化。</li>
              <li class="small">温度曲线：系统可通过温度曲线变化查询各分区的温度变化现状及历史温度曲线变化;</li>
              <li class="small">温度预测：对监测对象的温度变化趋势进行智能分析、预测。</li>
            </ul>
          </div>
          <div class="col-lg-7 col-md-7" data-aos="zoom-out-down" data-aos-delay="250">
            <img :src="require('@/assets/images/fs/ia-02-6.jpg')" alt="">
          </div>
        </div>
<!--        <hr>-->
<!--        <div class="row">-->
<!--          <div class="col-lg-5 col-md-5" data-aos="zoom-out-left" data-aos-delay="200">-->

<!--          </div>-->
<!--          <div class="col-lg-7 col-md-7" data-aos="zoom-out-right" data-aos-delay="250">-->
<!--            <img :src="require('@/assets/images/fs/ia-02-7.jpg')" alt="">-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from "@/components/elements/accordion/FSAccordion";
import Separator from "@/components/elements/separator/Separator";

export default {
  name: "IA2",
  components: {Separator, Accordion},
}

</script>
<style>
.p-pro-mr-0{
  margin:0
}
</style>
