<template>
  <div class="fs-service row row--15 service-wrapper">
    <div class="col-lg-3 col-md-6 col-sm-6 col-12" v-for="(service, index) in serviceList" :key=index>
      <router-link :to="`/industry-application/${service.routeId}`">
      <div data-aos="fade-up" data-aos-delay="70">
        <div :class="`service ${serviceStyle} ${checkTextAlign}`">
          <div class="inner">
            <div class="content">
              <h4 class="title">
<!--                <router-link to="/service-details" v-html="service.title"/>-->
                {{service.title}}
              </h4>
            </div>
            <div class="image">
              <img :src="service.image" alt="Service Images"/>
            </div>
            <div class="content">
              <p v-for="(desc) in service.descriptions" class="description b1 color-gray mb--0" v-html="desc"/>
            </div>
          </div>
        </div>
      </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FSService',
  props: {
    serviceStyle: {
      type: String
    },
    textAlign: {
      type: String,
      default: 'left'
    }
  },
  data() {
    return {
      serviceList: [
        {
          routeId:2,
          image: require('@/assets/images/fs/service-petrifaction.jpg'),
          title: '石化领域监测',
          descriptions: [
            '1、管道防外破监测;',
            '2、通管器定位跟踪监测.'
          ]
        },
        {
          routeId:1,
          image: require('@/assets/images/fs/service-communication.jpg'),
          title: '通讯领域监测',
          descriptions: [
            '1、通讯光缆故障定位监测;',
            '2、分缆定位识别;',
            '3、通讯光缆防破坏预警监测.'
          ]
        },
        {
          routeId:4,
          image: require('@/assets/images/fs/service-traffic.jpg'),
          title: '交通领域监测',
          descriptions: [
            '1、山体防护网落石监测;',
            '2、公路护栏撞击监测;'
          ]
        },
        {
          routeId:3,
          image: require('@/assets/images/fs/service-power.jpg'),
          title: '电力领域监测',
          descriptions: [
            '1、电缆防外破监测;',
            '2、电缆防盗监测;'
          ]
        }
      ]
    }
  },
  computed: {
    checkTextAlign() {
      if (this.textAlign === 'left') return 'text-start';
      else if (this.textAlign === 'center') return 'text-center';
      else if (this.textAlign === 'end') return 'text-end';
      else return '';
    }
  }
}
</script>
<style scoped>
.fs-service p.description{
  font-size: 14px;
  text-align: left;
}
.service.service__style--2 .image img{
  max-height: 320px;
}
.service.service__style--2:hover {
  /*transform: translate(-50px);*/
  transform: scaleX(1.3) scaleY(1.1);
  box-shadow: 0 0 39px rgb(255 101 80 / 10%);
}
</style>
