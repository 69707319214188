<template>
  <div class="wrapper">
    <p class="title"><b>光传输线路智能运维仪-远程控制端隐私政策</b></p>
    <p>
      <img
        src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEBLAEsAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wAARCAAKAAoDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD3+iiigD//2T8AOnAgS90AMZABABIwABxQ"
        alt=""
      /><a id="br1_0"></a>我们的隐私政策已于 2024 年 11 月 8 日更新，并于 2024 年
      11 月 8 日生效。
    </p>
    <p>引言</p>
    <p>
      光传输线路智能运维仪-远程控制端（Remote-controlled for
      IMIOT）是由无锡远知科技发展有限公司（以下简称“远知”或“我们”）为您提供对光传输线路智能运维仪远程控制服务的app。
    </p>
    <p>
      我们非常重视您的隐私。为了给您提供服务，光传输线路智能运维仪-远程控制端会按照本隐私权政策的规定使用和披露您的个人信息，并将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，光传输线路智能运维仪-远程控制端不会将这些信息对外披露或向第三方提供。光传输线路智能运维仪-远程控制端会不时更新本隐私权政策。
      您在同意光传输线路智能运维仪-远程控制端服务使用协议之时，即视为您已经同意本隐私权政策全部内容。
    </p>
    <p>
      我们希望为用户带来最好的体验。如果您对本隐私政策中描述的个人信息处理实践有任何疑问，请通过访问<a
        href="https://www.furthersense.com/#/about-us"
        >https://www.furthersense.com/#/about-us</a
      >联系我们，以便我们处理您的特殊需求。我们很高兴收到您的反馈。
    </p>
    <p>一、我们收集哪些信息以及如何使用信息</p>
    <p>（一）您须授权我们收集和使用您个人信息的情形</p>
    <p>
      收集个人信息的目的在于向您提供产品，并且保证我们遵守适用的相关法律、法规及其他规范性文件。您有权自行选择是否提供该信息，但多数情况下，如果您不提供，我们可能无法向您提供相应的服务，也无法回应您遇到的问题。这些功能包括：
    </p>
    <p><b>1.扫描二维码连接光传输线路智能运维仪</b></p>
    <p>
      您在使用光传输线路智能运维仪-远程控制端app时，若您需要使用扫描二维码的方式连接光传输线路智能运维仪这功能时，我们将会收集您手机的<b>camera相机权限</b>，允许app使用摄像头进行扫描二维码。
    </p>
    <p><b>2.地图显示/故障定位</b></p>
    <p>
      您在使用光传输线路智能运维仪-远程控制端app时，若您需要使用线路详情-测试线路-路由管理这功能时，我们需要您在您的设备中向我们开启您的<b>地理位置</b>（位置信息）。为了更精准地在路由管理界面展示基于地理位置以及导航服务，我们会使用第三方定位SDK（
      高德地图） 来实现定位。
    </p>
    <p>
      同时，为了更好地实现定位，在APP每次重启后我们都会初始化SDK，
      以获取精确的定位信息。在每次初始化的过程中，会收集IMSI、
      <b>Android ID</b>、
      MAC地址和地理位置信息。我们再次向您承诺，我们不会将我们获取的关于您在各时段的位置信息进行结合以判断您的行踪轨迹。
    </p>
    <p><b>3.数据管理存储</b></p>
    <p>
      您在未来使用光传输线路智能运维仪-远程控制端app时，所产生的配置信息等文件将本地存储于您的设备中，因此我们需要收集您设备的<b>Storage存储权限</b>和<b>读写权限</b>，以保障您对光传输线路智能运维仪-远程控制端app的正常使用。
    </p>
    <p><b>4.OTDR记录/故障记录/路由管理记录微信分享</b></p>
    <p>
      您在未来使用光传输线路智能运维仪-远程控制端app时<a id="br1_1"></a
      >，OTDR记录、故障记录、路由管理记录可以通过微信的方式共享给您的协作者，我们需要收集您设备的<b>位置信息，设备信息（Mac地址），SIM卡信息（ICCID），</b>以此保障微信分享功能的正常使用，从而提升您的用户体验。
    </p>
    <p>
      （二）您充分知晓，以下情形中，我们收集、使用个人信息无需征得您的同意：
    </p>
    <p>1、与国家安全、国防安全有关的；</p>
    <p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
    <p>3、与犯罪侦查、起诉、审判和判决执行等有关的；</p>
    <p>
      4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
    </p>
    <p>5、所收集的个人信息是个人信息主体自行向社会公众公开的；</p>
    <p>
      6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；
    </p>
    <p>7、根据您的要求签订合同所必需的；</p>
    <p>
      8、用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；
    </p>
    <p>9、为合法的新闻报道所必需的；</p>
    <p>
      10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
    </p>
    <p>（三）我们从第三方获得您个人信息的情形</p>
    <p>
      第三方调用的权限类型包括获取您的位置信息、读/写您的存储、读取您的设备信息（如IMEI、MAC地址、android_id）、读取SIM卡信息（subscriber
      ID，ICCID，运营商信息）、查看WLAN连接。
    </p>
    <p>第三方SDK权限采集个人信息说明：</p>
    <ul>
      <li><b>Zxing（二维码框架，Zxing二维码扫描）</b></li>
    </ul>
    <p>
      <b
        >SDK官网：<a href="https://github.com/zxing/zxing"
          >https://github.com/zxing/zxing</a
        ></b
      >
    </p>
    <p><b>企业主体：zxing</b></p>
    <p><b>使用目的：二维码扫描识别远程码</b></p>
    <p><b>收集信息类型：相机</b></p>

    <ul>
      <li><b>Barcode Scanning（条形码扫描,扫描识别二维码）</b></li>
    </ul>
    <p>
      <b
        >SDK官网：<a href="https://github.com/juliansteenbakker/mobile_scanner"
          >https://github.com/juliansteenbakker/mobile_scanner</a
        ></b
      >
    </p>
    <p><b>企业主体：juliansteenbakker</b></p>
    <p><b>使用目的：二维码扫描识别远程码</b></p>
    <p><b>收集信息类型：相机</b></p>
    <p>
      <b>依赖firebase和ML-Kit库，用于启动二维码扫描</b>
      <b>firebase隐私条款<a href="https://firebase.google.com/support/privacy?hl=zh-cn">https://firebase.google.com/support/privacy?hl=zh-cn</a></b>
      <b>ML-Kit库隐私条款<a href="https://developers.google.com/ml-kit/terms">https://developers.google.com/ml-kit/terms</a></b>
    </p>

    <ul>
      <li><b>微信分享SDK</b></li>
    </ul>
    <p>
      <b
        >SDK官网：<a href="https://open.weixin.qq.com/"
          >https://open.weixin.qq.com/</a
        ></b
      >
    </p>
    <p><b>企业主体：深圳市腾讯计算机系统有限公司</b></p>
    <p><b>使用目的：提供微信线路，采集记录等分享。</b></p>
    <p>
      <b>收集信息类型：位置信息，设备信息（Mac地址），SIM卡信息（ICCID）；</b>
    </p>
    <p>
      <b
        >隐私政策链接：<a
          href="https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&amp;t=weixin_agreement&amp;s=privacy"
          >https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&amp;t=weixin_agreement&amp;s=privacy</a
        ></b
      >
    </p>
    <ul>
      <li><b>高德地图SDK</b></li>
    </ul>
    <p>
      <b>SDK官网：<a href="https://lbs.amap.com/">https://lbs.amap.com/</a></b>
    </p>
    <p><b>企业主体：高德软件有限公司</b></p>
    <p><b>使用目的：提供地图显示，故障定位等功能。</b></p>
    <p>
      <b
        >收集信息类型：位置信息（经纬度、精确位置、粗略位置），设备信息（如IP
        地址、GNSS信息、WiFi状态、WiFi参数、WiFi列表、SSID、BSSID、基站信息、信号强度的信息、蓝牙信息、传感器信息（矢量、加速度、压力、方向、地磁）、设备信号强度信息、外部存储目录），设备标识信息（IMEI、IDFA、IDFV、Android
        ID、MEID、MAC地址、OAID、IMSI、ICCID、硬件序列号）。</b
      >
    </p>
    <p>
      <b
        >隐私政策链接：<a href="https://lbs.amap.com/pages/privacy/"
          >https://lbs.amap.com/pages/privacy/</a
        ></b
      >
    </p>
    <p>二、我们如何共享、公开披露您的信息</p>
    <p>（一）共享：</p>
    <p>我们不会将任何个人信息出售给第三方。</p>
    <p>
      我们有时可能会向第三方（定义见下文）共享您的个人信息，以便提供或改进我们的产品或服务，包括根据您的要求提供产品或服务。如果您不再希望允许我们共享这些信息，请通过访<a
        href="https://www.furthersense.com/#/about-us"
        >https://www.furthersense.com/#/about-us</a
      >联系我们。
    </p>
    <p>（二）公开披露</p>
    <p>
      在如下情况下，光传输线路智能运维仪-远程控制端将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：
    </p>
    <ul>
      <li>经您事先同意，向第三方披露；</li>
      <li>为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；</li>
      <li>
        根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
      </li>
      <li>
        如您出现违反中国有关法律、法规或者光传输线路智能运维仪-远程控制端服务协议或相关规则的情况，需要向第三方披露；
      </li>
      <li>
        如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；
      </li>
      <li>
        其它光传输线路智能运维仪-远程控制端根据法律、法规或者相关政策认为合适的披露。<a
          id="br1_9"
        ></a>
      </li>
    </ul>
    <p>三、我们如何保存和保护您的个人信息</p>
    <p>（一）安全措施</p>
    <p>
      1.
      我们承诺保证您的个人信息安全。为了防止未经授权的访问、披露或其他类似风险，我们落实了所有法律规定的物理、电子和管理措施流程，保护我们从您的移动设备上收集的信息。我们将确保依据适用的法律保护您的个人信息。
    </p>
    <p>
      2.
      您的个人信息全都被储存在安全的服务器上，并在受控设施中受到保护。我们定期审查信息收集、储存和处理实践，包括物理安全措施，以防止任何未经授权的访问和使用。
    </p>
    <p>
      3.
      我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
    </p>
    <p>
      4.
      互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他我们用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。
    </p>
    <p>（二）您能做什么?</p>
    <p>
      您可以通过不向任何人（除非此人经您正式授权）披露您的登录密码或账号信息，尤其是在他人的设备上登录时，在使用光传输线路智能运维仪-远程控制端app完成之后，请退出登录。
    </p>
    <p>四、您的权利</p>
    <p>
      按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
    </p>
    <p><b>个人信息主体注销账户</b></p>
    <p>
      您可以通过联系我们的客服或通过其他我们公示的方式注销您的账号（但法律法规另有规定的除外），一旦您注销账号，将无法使用我们提供的全线用户产品的服务，因此请您谨慎操作。我们将在15个工作日完成核查和处理，若您未行使上述权利，您的信息将持续为我们保存至实现服务目的所必需的时间。
    </p>
    <p>五、我们如何处理儿童的个人信息</p>
    <p>
      如果没有父母或监护人的同意，未成年人不得创建自己的用户账户。如您为未成年人的，建议您请您的父母或者监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。
    </p>
    <p>
      对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享转让或披露消息。
    </p>
    <p>六、数据出境</p>
    <p>
      原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。目前，我们并未涉及国际业务，所开展的业务类型基本不会导致您的个人信息转移到其他国家、地区。若后续业务及人员的变动将导致您的信息转移至境外，我们会事先征得您的同意。同时，我们会尽量确保您的个人信息得到在中华人民共和国境内足够同的保护。我们采取的措施是我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化安全举措。
    </p>
    <p>七、本隐私权政策如何更新</p>
    <p>
      我们适时会对本隐私权政策进行调整或变更，本隐私权政策的任何更新将以标注更新时间的方式公布在我们网站上或以及其他适当方式通知用户，除法律法规或监管规定另有强制性规定外，经调整或变更的内容一经通知或公布后的7日后生效，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在网站、移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问网站或者移动端隐私政策通知内容以便及时了解最新的隐私政策。
    </p>
    <p>八、公司相关信息及联系我们</p>
    <p>
      如果您对本隐私政策有任何意见或问题，或者您对我们收集、使用或披露您的个人信息有任何问题，请通过下方地址或通过访问<a
        href="https://www.furthersense.com/#/about-us"
        >https://www.furthersense.com/#/about-us</a
      >
      联系我们，并提及“隐私政策”。一般情况下，我们将在十五个工作日内作出答复。
    </p>
    <p>联系地址：江苏省无锡市新吴区新华路5号创新创意产业园C栋5层</p>
    <p>电话：0510-88999969</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.wrapper {
  font-size: 1.2rem;
  font-weight: 500;
  // line-height: 1.4rem;
  padding-bottom: 20px;

  .title {
    padding: 40px 20px 20px 20px;
    font-size: 2.2rem;
    // line-height: 2.3rem;
    font-weight: bold;
    text-align: center;
    text-indent: 0;
  }

  p {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 4px;
    margin-bottom: 8px;
    font-size: 1.2rem;
    font-weight: 500;
    // line-height: 1.3rem;
    text-indent: 2em;
  }

  b {
    font-weight: 700;
    color: #000;
  }

  table {
    width: 95%;
    margin-left: 10px;
    text-align: center;
    border-collapse: collapse;

    & tr td {
      border: 1px solid #a3abae;
    }
  }
  .underline {
    text-decoration: underline;
  }

  .explain {
    // height: 40px;
    font-size: 1.4rem;
    // line-height: 1.41rem;
    color: #a3abae;
    text-align: center;
    background-color: #efeff4;
  }

  a {
    color: blue;
  }

  ul {
    margin-left: 16px;
    margin-right: 16px;
    padding: 16px;
    margin-bottom: 8px;
    li {
      font-size: 1.2rem;
      line-height: 1.21rem;
    }
  }
}
</style>
